import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { SELLERS_BASE_URL, apiErrorManagemnt } from '../../api/Custom';
import Swal from 'sweetalert2';
import ShieldIcon from '@mui/icons-material/Shield';
import { getToken } from '../../custome/action';
import { Button } from '@mui/material';
import { DASHBOARD_BASE_PATH } from '../../custome/constants';

const SPAPIAuthorization = () => {
  const [error, setError] = useState('');
  const [isSuccess,setIsSuccess]=useState(false)
  const location = useLocation();
  const navigate = useNavigate()

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const state = params.get("state");
    const spapi_oauth_code = params.get('spapi_oauth_code');

    const fetchTokens = async () => {
      try {
        // const sessionState=window?.sessionStorage.get("state");
        // alert(sessionState)
        // if(sessionState!=state){
        //   Swal.fire("Error","State value not match..","error");
        //   return;
        // }
        const channelId = state.split("~")[1];
        //sessionStorage.removeItem("state");
        // const response = await axios.post('/getTokens', { spapi_oauth_code });
        const url=`${SELLERS_BASE_URL}/generateAmazonTokens?spapi_oauth_code=${spapi_oauth_code}&channelId=${channelId}`;
        await axios.get(url, {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
          }
        }).then(
          (response) => {
            if (response?.data?.status === 0) {
              setIsSuccess(true)
              // setAccessToken(response?.data?.access_token);
              // setRefreshToken(response?.data?.refresh_token);
              Swal.fire('Success',response?.data?.message,'success')
            }else{
              setIsSuccess(false);
              setError(response?.data?.message);
              Swal.fire('ERROR',response?.data?.message,'error');
            }
          },
          (error) => {
            setIsSuccess(false);
            setError(JSON.stringify(error))
           // apiErrorManagemnt(error);
            return error;
          }
        ).catch(err => {
          return (err)
        });

      } catch (error) {
        setIsSuccess(false)
        setError('Error fetching tokens');
      }
    };

    if (spapi_oauth_code) {
      fetchTokens();
    }else{
      setError("spapi_oauth_code not present in response !!")
    }
  }, [location.search]);

  return (
    <div>
      {isSuccess ? (
        <div className='alert alert-light text-center m-5'>
          <ShieldIcon color="success" style={{width:"50px", height:"50px"}} />
           <h5 className='py-4'>ManageEcom Authorization successful! Your new access token and refresh token have been generated.</h5>
            <Button size="small" variant='contained' onClick={()=>{navigate(DASHBOARD_BASE_PATH)}} > Back To Dashboard</Button>
        </div>
      ) : (
        <h6 className='text-center my-3'>{error || 'Loading...'}</h6>
      )}
    </div>
  );
};

export default SPAPIAuthorization;
