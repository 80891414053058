import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SELLERS_BASE_URL } from '../../api/Custom'
import { getToken } from '../../custome/action'

export const stockHistoryApi = createApi({
    reducerPath: 'stockHistoryApi',
    baseQuery: fetchBaseQuery({ baseUrl: SELLERS_BASE_URL }),
    tagTypes: ['stockHistory'],
    endpoints: (builder) => ({
        getStockHistory: builder.query({
            query: (state) => ({
                url: `/getStockHistoryReport?page=${state?.page - 1}&limit=${state?.limit}&searchBy=${state?.searchBy}&searchValue=${state?.searchValue}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ['stockHistory']
        }),
       
    })
})

export const {  useGetStockHistoryQuery } = stockHistoryApi