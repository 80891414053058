import { getToken } from "../custome/action";
import { Toast } from "../custome/popupMessage";
import { SELLERS_BASE_URL } from "./Custom";

export const dawnloadProductErrorExcelFile = (jobId,fileName) => {
    if(fileName===undefined){
        fileName="Error_excel.xlsx"
    }
    fetch(SELLERS_BASE_URL + "/downloadErrorExlFile?jobId=" + jobId, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        }
    })
        .then(response => {
            response?.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
                Toast.fire({
                    icon: 'info',
                    title: "product data error excel file downloaded, fix it."
                })
            });
        });
}

export const dawnloadExcelFile = async(warehouseId,type) => {

    await fetch(SELLERS_BASE_URL + "/getExcelFilesOfRecord?warehouseId=" +warehouseId+"&type="+type, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + getToken(),
        }
    })
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = type+".xlsx";
                a.click();
                Toast.fire({
                    icon: 'success',
                    title: "product data excel file downloaded."
                })
            });
            return false;
        });
        
}