import React, { useState } from 'react'
import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Modal, Pagination, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { apiErrorManagemnt2 } from '../../../api/Custom';
import Loader from '../../../components/loader/Loader';
import InfoIcon from '@mui/icons-material/Info';
import { useUpdateRequestData } from '../../../hooks/useUpdateRequestData';
import FontAwesome from 'react-fontawesome';
import { UPDATE_TYPE_WAREHOUSE } from '../../../custome/constants';
import { useDispatch } from 'react-redux';
import { removeUpdateDetails, setUpdateDetails } from '../../../store/updateData';
import Swal from 'sweetalert2';
import { useGetAllWarehousesQuery, useSaveWarehouseMutation, useUpdateWarehouseMutation } from '../../../hooks/RTK_Service/warehouse';
import GuideTour from '../../../components/guide/GuideTour';
import { steps } from './guideSteps';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};
export default function Warehouse() {
    const [startTour, setStartTour] = useState(false);
    const updateWarehouse = useUpdateRequestData(UPDATE_TYPE_WAREHOUSE);
    const [warehouseType, setWarehouseType] = useState(updateWarehouse != null ? updateWarehouse.warehouseTypeId : -1);
    const [showAddUpdateWarehousePanel, setShowAddUpdateWarehousePanel] = useState(false);
    const [warehouseDataError, setWarehouseDataError] = useState({ type: null, isError: false, message: null })
    const [pageNumber, setPageNumber] = useState(1);
    const dispatch = useDispatch();


    // const warehouseData = useGetWarehouseData(pageNumber);
    const obj = {
        page: pageNumber,
        limit: 10
    }
    const warehouseData = useGetAllWarehousesQuery(obj)
    if (warehouseData?.isError) {
        apiErrorManagemnt2(warehouseData.error)
    }


    // const updateMutation = useUpdateWarehouseData(pageNumber);
    //const saveWarehouseMutation = useSaveWarehouseData();
    const [saveWarehouse, { isLoading: warehouseIsLoading, isError: isWarehouseError, error: warehouseError }] = useSaveWarehouseMutation();
    const [updateWarehouseData, { isLoading: wupdateIsLoading, isError: wupdateisError, error: wupdateError }] = useUpdateWarehouseMutation();

    if (isWarehouseError) {
        apiErrorManagemnt2(warehouseError);
    }
    if (wupdateisError) {
        apiErrorManagemnt2(wupdateError);
    }

    if (warehouseData?.isLoading) {
        return <Loader action={true}></Loader>
    }

    const handleAddUpdateWarehouse = (event) => {
        event.preventDefault();
        const fd = new FormData(event.currentTarget);

        if (fd.get("warehouseName") == null || fd.get("warehouseName").length <= 0) {
            setWarehouseDataError({ type: "wName", isError: true, message: "warehouse name is required !!" })
        } else if (fd.get("warehouseEmailId") === null || fd.get("warehouseEmailId").length <= 0) {
            setWarehouseDataError({ type: "wEmail", isError: true, message: "warehouse email id is required !!" })
        } else if (fd.get("warehouseContactNo") === null || fd.get("warehouseContactNo").length <= 0) {
            setWarehouseDataError({ type: "wCNO", isError: true, message: "warehouse contact number required" })
        } else if (fd.get("warehouseAddress") === null || fd.get("warehouseAddress").length <= 0) {
            setWarehouseDataError({ type: "wAddr", isError: true, message: "warehouse address is required" })
        } else if (warehouseType === '' || warehouseType == null || warehouseType === -1) {
            setWarehouseDataError({ type: "wType", isError: true, message: "select warehouse type first !!" })

            // }else if (fd.get("pincode") ===null || fd.get("pincode").length <= 5 ) {
            //     setWarehouseDataError({ type: "pincode", isError: true, message: "invalid pincode" })
            // }else if (countryId===0 || countryId==0 ) {
            //     setWarehouseDataError({ type: "countryId", isError: true, message: "select country" })
            // }else if (stateId===0 || stateId==0 ) {
            //     setWarehouseDataError({ type: "stateId", isError: true, message: "select state" })
            // }else if (cityId===0 || cityId==0 ) {
            //     setWarehouseDataError({ type: "cityId", isError: true, message: "select city" })
            // } else {

        } else {
            setWarehouseDataError({ type: null, isError: false, message: null });
            var warehouseData = {
                warehouseName: fd.get("warehouseName"),
                warehouseAddress: fd.get("warehouseAddress"),
                warehouseContactNo: fd.get("warehouseContactNo"),
                warehouseEmailId: fd.get("warehouseEmailId"),
                warehouseTypeId: warehouseType,
                pincode: fd.get("pincode"),

                companyName: fd.get("companyName")
            }

            if (updateWarehouse === null || updateWarehouse == null) {
                // saveWarehouseMutation?.mutateAsync(warehouseData).then((res)=>{
                //     if (res?.data?.status === 0) {
                //         Swal.fire("Success", res?.data?.message, "success")
                //     }
                //     setShowAddUpdateWarehousePanel(false);
                // })

                saveWarehouse(warehouseData).then((res) => {
                    if (res?.data?.status === 0) {
                        Swal.fire("Success", res?.data?.message, "success")
                    }
                    setShowAddUpdateWarehousePanel(false);
                })
            } else if (updateWarehouse != null) {
                warehouseData = { ...warehouseData, warehouseId: updateWarehouse.warehouseId }
                updateWarehouseData(warehouseData).then((res) => {
                    if (res?.data?.status === 0) {
                        Swal.fire("Success", res?.data?.message, "success")
                    }
                    setShowAddUpdateWarehousePanel(false);
                })
            }
        }
    }




    const handleUpdateWarehouseData = (data) => {
        dispatch(setUpdateDetails({ type: UPDATE_TYPE_WAREHOUSE, data: data }))
        setShowAddUpdateWarehousePanel(!showAddUpdateWarehousePanel)
    }

    // const handleCountryChange = (event) => {
    //     getStatesList(event.target.value).then(res => {
    //         setStateList(res?.data?.data);
    //         setCountryId(event.target.value);
    //         setUpdate(!update);
    //     })
    // }

    // const handleStateChange = (event) => {
    //     setStateId(event.target.value);
    //     getCitysList(event.target.value).then(res => {
    //         setCityList(res?.data?.data);
    //     })
    // }

    // const handleCityChange = (event) =>{
    //     setCityId(event.target.value);
    // }

    return (
        <div className='container-fluid'>
            {
                startTour &&
                <GuideTour action={startTour} guideSteps={steps} />
            }
            <div className="spaceBetween mt-1">
                <div>
                    <div className='alignCenter'>
                        <h2>MANAGE WAREHOUSE</h2>
                    </div>
                </div>
                <Tooltip onClick={() => { setStartTour(true) }} className='pointer mr-3' title="Start the manage product page information tour" >
                    <InfoIcon />
                </Tooltip>
            </div>

            <div className='left my-2'>
                <Button className="addWarehouseBtn" onClick={() => { dispatch(removeUpdateDetails()); setShowAddUpdateWarehousePanel(!showAddUpdateWarehousePanel) }} variant='contained' >+ ADD WAREHOUSE</Button>
            </div>
            <div className="table-responsive mt-2">
                <table className="table table-hover mt-2">
                    <thead style={{ borderBottom: "1px solid black" }}>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">WAREHOUSE NAME</th>
                            <th scope="col">ADDRESS</th>
                            <th scope="col">CONTACT NO</th>
                            <th scope="col">EMAIL ID</th>
                            <th scope="col">TYPE</th>
                            <th scope="col">UPDATE RECORD</th>
                            <th scope="col">DELETED RECORD</th>
                        </tr>
                    </thead>
                    <tbody>
                        {warehouseData?.data?.data?.content?.map((data) => {
                            return (
                                <tr key={data?.warehouseId}>
                                    <td className='warehouseId text-left'>{data?.warehouseId}</td>
                                    <td className='text-left'>{data?.warehouseName}</td>
                                    <td className='text-left'>{data?.warehouseAddress}</td>
                                    <td className='text-left'>{data?.warehouseContactNo}</td>
                                    <td className='text-left'>{data?.warehouseEmailId}</td>
                                    <td className='text-left'>{data?.warehouseTypeId}</td>
                                    <td><button onClick={() => { handleUpdateWarehouseData(data) }} className='btn btn-sm btn-outline-warning warehouseUpdate'>Update</button></td>
                                    <td><button className='btn btn-sm btn-outline-danger'>Delete</button></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="right">
                    <Stack spacing={2}>
                        <Pagination color="primary" count={warehouseData?.data?.data?.totalPages} page={pageNumber} onChange={(e, v) => setPageNumber(v)} />
                    </Stack>
                </div>

            </div>


            {/* modal for add update */}
            <Modal
                open={showAddUpdateWarehousePanel}
                onClose={() => { setShowAddUpdateWarehousePanel(!showAddUpdateWarehousePanel) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography className="text-primary" id="modal-modal-title" variant="h6" component="h2">
                        <FontAwesome name="magnifying-glass" className='px-2' />

                        {updateWarehouse != null ? <>UPDATE</> : <>ADD</>} WAREHOUSE
                    </Typography>
                    <Box component="form" onSubmit={handleAddUpdateWarehouse} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            size='small'
                            fullWidth
                            id="warehouseName"
                            error={warehouseDataError?.type == 'wName' && warehouseDataError?.isError}
                            helperText={warehouseDataError?.type == 'wName' ? warehouseDataError?.message : ''}
                            label="Warehouse name"
                            autoFocus
                            multiline
                            defaultValue={updateWarehouse != null ? updateWarehouse.warehouseName : ''}
                            name="warehouseName"
                        />
                        <TextField
                            margin="normal"
                            size='small'
                            fullWidth
                            id="companyName"
                            error={warehouseDataError?.type == 'cwName' && warehouseDataError?.isError}
                            helperText={warehouseDataError?.type == 'cwName' ? warehouseDataError?.message : ''}
                            label="Company name"
                            multiline
                            defaultValue={updateWarehouse != null ? updateWarehouse.companyName : ''}
                            name="companyName"
                        />

                        <TextField
                            margin="normal"
                            size='small'
                            fullWidth
                            id="warehouseEmailId"
                            label="Warehouse email id"
                            error={warehouseDataError?.type === 'wEmail' && warehouseDataError?.isError}
                            helperText={warehouseDataError?.type === 'wEmail' ? warehouseDataError?.message : ''}
                            name="warehouseEmailId"
                            multiline
                            defaultValue={updateWarehouse != null ? updateWarehouse.warehouseEmailId : ''}
                        />
                        <TextField
                            margin="normal"
                            size='small'
                            fullWidth
                            id="warehouseContactNo"
                            label="Warehouse contact number"
                            name="warehouseContactNo"
                            error={warehouseDataError?.type === 'wCNO' && warehouseDataError?.isError}
                            helperText={warehouseDataError?.type === 'wCNO' ? warehouseDataError?.message : ''}
                            multiline
                            defaultValue={updateWarehouse != null ? updateWarehouse.warehouseContactNo : ''}
                        />
                        <FormControl sx={{ minWidth: "100%" }}>
                            <InputLabel id="search-status-label">Select status</InputLabel>
                            <Select
                                labelId="search-status-label"
                                size='small'
                                id="search-status"
                                value={warehouseType}
                                error={warehouseDataError?.type === 'wType' && warehouseDataError?.isError}
                                helperText={warehouseDataError?.type === 'wType' ? warehouseDataError?.message : ''}
                                label="Select state"
                                onChange={(e) => setWarehouseType(e.target.value)}
                            >
                                <MenuItem value="">
                                    <em>Select warehouse type</em>
                                </MenuItem>
                                <MenuItem value={1}>Permanent</MenuItem>
                                <MenuItem value={2}>Temprary</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="normal"
                            size='small'
                            fullWidth
                            id="warehouseAddress"
                            label="Warehouse address"
                            name="warehouseAddress"
                            error={warehouseDataError?.type === 'wAddr' && warehouseDataError?.isError}
                            helperText={warehouseDataError?.type === 'wAddr' ? warehouseDataError?.message : ''}
                            multiline
                            rows={2}
                            defaultValue={updateWarehouse != null ? updateWarehouse.warehouseAddress : ''}
                        />

                        {/* <div className='row'>
                            <div className="col-6">
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="warehousePincode"
                                    label="Pincode"
                                    name="pincode"
                                    required={true}
                                    error={warehouseDataError?.type === 'pincode' && warehouseDataError?.isError}
                                    helperText={warehouseDataError?.type === 'pincode' ? warehouseDataError?.message : ''}
                                    multiline
                                    defaultValue={updateWarehouse != null ? updateWarehouse?.pincode : ''}
                                />
                            </div>


                            <div className="col-6">
                                <FormControl sx={{mt:2, width:"100%" }}>
                                    <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="Country"
                                        error={warehouseDataError?.type === 'countryId' && warehouseDataError?.isError}
                                        helperText={warehouseDataError?.type === 'countryId' ? warehouseDataError?.message : ''}
                                        defaultValue={updateWarehouse != null ? updateWarehouse?.countryId : ''}
                                        onChange={(event)=>{handleCountryChange(event)}}
                                    >
                                        <MenuItem value="101">
                                            <em>India</em>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <FormControl sx={{ width:"100%" }}>
                                    <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        error={warehouseDataError?.type === 'stateId' && warehouseDataError?.isError}
                                        helperText={warehouseDataError?.type === 'stateId' ? warehouseDataError?.message : ''}
                                        defaultValue={updateWarehouse != null ? updateWarehouse?.stateId : ''}
                                        label="State"
                                        onChange={(event)=>{handleStateChange(event)}}
                                    >
                                        <MenuItem value="">
                                            <em>select state</em>
                                        </MenuItem>
                                        {
                                            stateList?.map(stateData=>{
                                               return <MenuItem value={stateData?.id}>{stateData?.state_name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-6">
                            <FormControl sx={{width:"100%"}}>
                                    <InputLabel id="demo-simple-select-helper-label">Select City</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        error={warehouseDataError?.type === 'cityId' && warehouseDataError?.isError}
                                        helperText={warehouseDataError?.type === 'cityId' ? warehouseDataError?.message : ''}
                                        defaultValue={updateWarehouse != null ? updateWarehouse?.cityId : ''}
                                        label="Select City"
                                        onChange={(event)=>{handleCityChange(event)}}
                                    >
                                        <MenuItem value="">
                                            <em>select city</em>
                                        </MenuItem>
                                        {
                                            cityList?.map(cityData=>{
                                               return <MenuItem value={cityData?.id}>{cityData?.city_name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div> */}





                        {/* <TextField
                            margin="normal"
                            fullWidth
                            id="warehouseGps"
                            label="gps"
                            name="gps"
                            error={warehouseDataError?.type === 'gps' && warehouseDataError?.isError}
                            helperText={warehouseDataError?.type === 'gps' ? warehouseDataError?.message : ''}
                            multiline
                            defaultValue={updateWarehouse != null ? updateWarehouse.gps : ''}
                        /> */}
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{ mt: 2, py: 1 }}
                        >
                            {updateWarehouse != null ? <>UPDATE</> : <>ADD</>} WAREHOUSE
                            {
                                (warehouseIsLoading || wupdateIsLoading)
                                && <FontAwesome name="circle-notch" spin className='fa-2x px-3' />
                            }
                        </Button>
                    </Box>
                </Box>
            </Modal>



        </div>
    )
}
