import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react'
import axios from "axios";
import { getToken } from '../../../custome/action';
import { BASE_URL, apiErrorManagemnt2 } from "../../../api/Custom";
import { useUpdateRequestData } from '../../../hooks/useUpdateRequestData';
import { UPDATE_TYPE_CHANNEL } from '../../../custome/constants';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeUpdateDetails } from '../../../store/updateData';
import { useGetAllWarehousesQuery } from '../../../hooks/RTK_Service/warehouse';
import { useSaveChannelDetailsMutation, useUpdateChannelDetailsMutation } from '../../../hooks/RTK_Service/channel';

const channelList = [
    {
        value: "AMAZON",
        displayName: "AMAZON"
    },
    {
        value: "FLIPKART",
        displayName: "FLIPKART"
    },
    {
        value: "MAGENTO",
        displayName: "MAGENTO"
    },
    {
        value: "SNAPDEAL",
        displayName: "SNAPDEAL"
    },
]

export default function AddChannel() {
    const updatedData = useUpdateRequestData(UPDATE_TYPE_CHANNEL);
    //console.log(JSON.stringify(updatedData));

    const [channelName, setChannelName] = React.useState(updatedData != null ? updatedData?.channel : '');
    const [orderSync, setOrderSync] = React.useState(updatedData != null ? updatedData?.orderSync : '');
    const [autoVerifyOrder, setAutoVerifyOrder] = React.useState(updatedData != null ? updatedData?.autoVerifyOrders : '');
    const [inventorySync, setInventorySync] = React.useState(updatedData != null ? updatedData?.inventorySync : '');
    const [warehouse, setWarehouse] = React.useState(updatedData != null ? updatedData?.warehouseId : 0);
    const [channelFormError, setChannelFormError] = React.useState({ isError: false, type: null, message: null });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [saveChannel, { isLoading: channelIsLoading, isError: isChannelError, error: channelError }] = useSaveChannelDetailsMutation();
    const [updateChannelData, { isLoading: cupdateIsLoading, isError: cupdateisError, error: cupdateError }] = useUpdateChannelDetailsMutation();

    var obj = {
        page: 1,
        limit: 1000,
    }
    const warehouseData = useGetAllWarehousesQuery(obj)
    if (warehouseData?.isError) {
        apiErrorManagemnt2(warehouseData?.error);
    }

    if (isChannelError) {
        apiErrorManagemnt2(channelError);
    }
    if (cupdateisError) {
        apiErrorManagemnt2(cupdateError);
    }

    const handleChangeChannelName = (event) => {
        setChannelName(event.target.value);
    };

    const handleChangeOrderSync = (event) => {
        setOrderSync(event.target.value);
    };

    const handleChangeAutoVerifyOrder = (event) => {
        setAutoVerifyOrder(event.target.value);
    };

    const handleChangeInventorySync = (event) => {
        setInventorySync(event.target.value);
    };

    const handleChangeWarehouse = (event) => {
        setWarehouse(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (data.get("channelname") == null || data.get("channelname").length === 0) {
            setChannelFormError({ isError: true, type: "channelname", message: "channel name is required !!" })
        } else if (channelName?.length <= 0) {
            setChannelFormError({ isError: true, type: "channelname2", message: "select the channel !!" })
        } else if (orderSync?.length <= 0) {
            setChannelFormError({ isError: true, type: "orderSync", message: "select the order sync yes/no !!" })
        } else if (autoVerifyOrder?.length <= 0) {
            setChannelFormError({ isError: true, type: "autoVerifyOrder", message: "select the auto verify order yes/no !!" })
        } else if (inventorySync?.length <= 0) {
            setChannelFormError({ isError: true, type: "inventorySync", message: "select the inventory sync yes/no !!" })
        } else if (data.get("maxInventory")?.length <= 0) {
            setChannelFormError({ isError: true, type: "maxInventory", message: "max inventory is required !!" })
        } else if (channelName !== "FLIPKART" && warehouse === 0) {
            setChannelFormError({ isError: true, type: "warehouse", message: "select the warehouse !!" })
        } else {
            var dataResult = {
                channelName: data.get("channelname"),
                channelGst: data.get("channelgst"),
                orderSync: orderSync,
                autoVerifyOrders: autoVerifyOrder,
                inventorySync: inventorySync,
                maxInventory: data.get("maxInventory"),
                channel: channelName,
                channelSellerName: data.get("channelsellername"),
                addressWithoutPinCode: data.get("addresswithoutpincode"),
                pincode: data.get("pincode"),
                warehouseId: warehouse,
            }

            if (updatedData === null) {
                saveData(dataResult)
                navigate("/dashboard/manage_channel");
            } else if (updatedData !== null) {
                dataResult = { ...dataResult, channelId: updatedData.channelId }
                updateData(dataResult);
                dispatch(removeUpdateDetails())
                navigate("/dashboard/manage_channel");
            }
        }
    }

  

    const updateData = async (data) => {
        // await axios.post(`${BASE_URL}/manageecom/api/updateChannel`, data, {
        //     headers: {
        //         'Authorization': 'Bearer ' + getToken(),
        //         'Content-Type': 'application/json'
        //     }
        // }).then(
        //     (response) => {
        //         if(response?.data?.status===0){
        //             Swal.fire("Data Updated.",response?.data?.message,"success")
        //         }
        //     },
        //     (error) => {
        //         alert(error.message)
        //         Swal.fire("Error",error?.data?.message,"error");

        //     }
        // ).catch(err => {
        //     return (err)
        // });

        updateChannelData(data).then((res) => {
            if (res?.data?.status === 0) {
                Swal.fire("Data Updated.", res?.data?.message, "success")
            }
        })
    }

    const saveData = async (data) => {
        // await axios.post(`${BASE_URL}/manageecom/api/createChannel`, data, {
        //     headers: {
        //         'Authorization': 'Bearer ' + getToken(),
        //         'Content-Type': 'application/json'
        //     }
        // }).then(
        //     (response) => {
        //         if(response?.data?.status===0){
        //             Swal.fire("Saved",response?.data?.message,"success")
        //         }
        //     },
        //     (error) => {
        //         alert(error.message)
        //         Swal.fire("Error",error?.data?.message,"error");
        //     }
        // ).catch(err => {
        //     return (err)
        // });
        saveChannel(data).then(res => {
            if (res?.data?.status === 0) {
                Swal.fire("Data Saved.", res?.data?.message, "success")
            }
        })
    }
    return (
        <>
            <div className='text-center'>
                <div className=" text-muted">
                    <h2>{updatedData != null ? <>UPDATE</> : <>ADD</>} CHANNEL</h2>
                </div>
            </div>

            <Box className='center' component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container xs={12} sm={6} md={7} >
                    <Grid item xs={12} >
                        <TextField
                            autoComplete="given-name"
                            name="channelname"
                            required
                            fullWidth
                            id="channelname"
                            label="Channel Name"
                            error={channelFormError?.type == 'channelname' && channelFormError?.isError}
                            helperText={channelFormError?.type == 'channelname' ? channelFormError?.message : ''}
                            multiline
                            defaultValue={updatedData != null ? updatedData?.channelName : ""}
                            autoFocus
                            sx={{ mb: 2 }}

                        />
                    </Grid>

                    <Grid item xs={12} sm={6} mb={2} pr={1}>
                        <FormControl sx={{ minWidth: "100%", }}>
                            <InputLabel id="demo-simple-select-helper-label">Channel name</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={channelName}
                                error={channelFormError?.type == 'channelname2' && channelFormError?.isError}
                                helperText={channelFormError?.type == 'channelname2' ? channelFormError?.message : ''}
                                label="Channel name"
                                onChange={handleChangeChannelName}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    channelList?.map((item) => <MenuItem value={item.value}>{item.displayName}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} mb={2}>
                        <FormControl sx={{ minWidth: "100%" }}>
                            <InputLabel id="demo-simple-select-helper-label">Order sync</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={orderSync}
                                label="Order sync"
                                error={channelFormError?.type == 'orderSync' && channelFormError?.isError}
                                helperText={channelFormError?.type == 'orderSync' ? channelFormError?.message : ''}
                                onChange={handleChangeOrderSync}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={'Y'}>YES</MenuItem>
                                <MenuItem value={'N'}>NO</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} mb={2} pr={1}>
                        <FormControl sx={{ minWidth: "100%" }}>
                            <InputLabel id="demo-simple-select-helper-label">Auto verify orders</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={autoVerifyOrder}
                                error={channelFormError?.type == 'autoVerifyOrder' && channelFormError?.isError}
                                helperText={channelFormError?.type == 'autoVerifyOrder' ? channelFormError?.message : ''}
                                label="Auto verify orders"
                                onChange={handleChangeAutoVerifyOrder}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={'Y'}>YES</MenuItem>
                                <MenuItem value={'N'}>NO</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} mb={2} >
                        <FormControl sx={{ minWidth: "100%" }}>
                            <InputLabel id="demo-simple-select-helper-label">Inventory sync</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={inventorySync}
                                error={channelFormError?.type == 'inventorySync' && channelFormError?.isError}
                                helperText={channelFormError?.type == 'inventorySync' ? channelFormError?.message : ''}
                                label="Inventory sync"
                                onChange={handleChangeInventorySync}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={'Y'}>YES</MenuItem>
                                <MenuItem value={'N'}>NO</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} mb={2} pr={1}>
                        <TextField
                            autoComplete="given-name"
                            name="maxInventory"
                            error={channelFormError?.type == 'maxInventory' && channelFormError?.isError}
                            helperText={channelFormError?.type == 'maxInventory' ? channelFormError?.message : ''}
                            required
                            fullWidth
                            multiline
                            defaultValue={updatedData != null ? updatedData.maxInventory : ""}
                            id="maxInventory"
                            label="Max Inventory"
                        />
                    </Grid>

                    {
                        (channelName !== "FLIPKART" || channelName != "FLIPKART") &&
                        <Grid item xs={12} sm={6}>
                            <FormControl sx={{ minWidth: "100%" }}>
                                <InputLabel id="WarehouseID">Warehouse</InputLabel>
                                <Select
                                    labelId="WarehouseID"
                                    id="WarehouseID"
                                    value={warehouse}
                                    label="WarehouseID"
                                    error={channelFormError?.type == 'warehouse' && channelFormError?.isError}
                                    helperText={channelFormError?.type == 'warehouse' ? channelFormError?.message : ''}
                                    onChange={handleChangeWarehouse}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>

                                    {
                                        warehouseData?.data?.data?.content?.map((wd) => {
                                            return <MenuItem value={wd.warehouseId}>{wd.warehouseName}</MenuItem>
                                        })
                                    }



                                </Select>
                            </FormControl>
                        </Grid>

                    }
                    <Grid item xs={12} mt={3} mb={2} className='center'>
                        <small className='text-muted'>Optional fields for invoice generation.</small><br />
                    </Grid>
                    <Grid item xs={12} sm={6} mb={2} pr={1}>
                        <TextField
                            autoComplete="given-name"
                            name="channelgst"
                            fullWidth
                            multiline
                            defaultValue={updatedData != null ? updatedData.channelGst : ""}
                            id="channelgst"
                            label="Channel GST"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} mb={2} >
                        <TextField
                            autoComplete="given-name"
                            name="channelsellername"
                            fullWidth
                            multiline
                            defaultValue={updatedData != null ? updatedData.channelSellerName : ""}
                            id="channelsellername"
                            label="Channel seller name"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} mb={2} pr={1}>
                        <TextField
                            autoComplete="given-name"
                            name="addresswithoutpincode"
                            fullWidth
                            multiline
                            defaultValue={updatedData != null ? updatedData.addressWithoutPinCode : ""}
                            id="addresswithoutpincode"
                            label="Address without pin code"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} mb={2} >
                        <TextField
                            autoComplete="given-name"
                            name="pincode"
                            fullWidth
                            multiline
                            defaultValue={updatedData != null ? updatedData.pincode : ""}
                            id="pincode"
                            label="Pin code"
                        />
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 3, mb: 2, py: 2 }}
                    >
                        {updatedData != null ? <>Update</> : <>Create</>} Channel
                    </Button>
                </Grid>

            </Box>

        </>

    )
}
