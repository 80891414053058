import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SELLERS_BASE_URL } from '../../api/Custom'
import { getToken } from '../../custome/action'

export const stockApi = createApi({
    reducerPath: 'stockApi',
    baseQuery: fetchBaseQuery({ baseUrl: SELLERS_BASE_URL }),
    tagTypes: ['stockData'],
    endpoints: (builder) => ({
        //this is when selected warehouse
        getStockDetailsByWarehouseId: builder.mutation({
            query: (data) => ({
                url: `/getStockDetailsByWarehouseId?pageNumber=${data?.pageNumber - 1}&warehouseId=${data?.warehouseId}&limit=100`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ["stockData"]
        }),
        saveStockDetail: builder.mutation({
            query: (data) => ({
                url: `/saveStockDetails`,
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags: ["stockData"]
        }),
        
        deleteStockDetail: builder.mutation({
            query: (data) => ({
                url: `/deleteStockDetails?stockId=${data}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags: ["stockData"]
        }),

        getAllStockDetails: builder.query({
            query: () => ({
                url: `/getAllStockDetails`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ["stockData"]
        }),

        //this is init 
        getStockDetails: builder.mutation({
            query: (pageNumber) => ({
                url: `/getStockDetails?pageNumber=${pageNumber - 1}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ["stockData"]
        }),


        // saveJobAndBatchExcelData : builder.mutation({
        //     query:(data)=>({
        //         url: `/uploadExcelFile`,
        //         method: 'POST',
        //         body:data,
        //         headers: {
        //             'Authorization': 'Bearer ' + getToken(),
        //             "Content-Type": "multipart/form-data",
        //             "x-rapidapi-host": "file-upload8.p.rapidapi.com",
        //             "x-rapidapi-key": "your-rapidapi-key-here",
        //         },
        //     }),
        //     invalidatesTags:["stock"]
        // }),

        updateStockDetails: builder.mutation({
            query: (data) => ({
                url: `/updateStockDetails`,
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags: ["stockData"]
        }),

        searchStocks: builder.mutation({
            query: (data) => ({
                url: `/searchStock?searchBy=${data?.searchBy}&searchValue=${data?.searchValue}&warehouseId=${data?.warehouseId}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ["stockData"]
        }),

        getAllStockDetailsByWarehouseId: builder.query({
            query: (warehouseId) => ({
                url: `/getStockDetailsByWarehouseId?pageNumber=0&warehouseId=${warehouseId}&fetchAll=true`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ['stockData']
        }),

    })
})

export const { useGetAllStockDetailsQuery, useSaveStockDetailMutation,useDeleteStockDetailMutation, useUpdateStockDetailsMutation, useGetStockDetailsMutation, useGetStockDetailsByWarehouseIdMutation, useGetAllStockDetailsByWarehouseIdQuery, useSearchStocksMutation } = stockApi