import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SELLERS_BASE_URL } from '../../api/Custom'
import { getToken } from '../../custome/action'

export const channelOrdersApi = createApi({
    reducerPath: 'channelOrdersApi',
    baseQuery: fetchBaseQuery({ baseUrl: SELLERS_BASE_URL }),
    tagTypes : ['channelOrders'],
    endpoints: (builder) => ({
        getChannelOrders: builder.query({
            query: (state) => ({
                url: `/getChannelOrders?page=${state?.page - 1}&limit=${state?.limit}&channel=${state?.channel}&status=${state?.status}`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags : ['channelOrders']
        }),
        generateLabels :  builder.mutation({
            query : (data) =>({
                url: `/generateLabels`,
                method: 'POST',
                body:data,
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['channelOrders']
        }),
       
    })
})

export const {useGetChannelOrdersQuery, useGenerateLabelsMutation} = channelOrdersApi