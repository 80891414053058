import {  Stack, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React, {  useState } from 'react'
import { useGetMarketplaceOrderDetailsQuery, useGetMarketplaceOrdersByWarehouseQuery, useShipMarketplaceOrderMutation } from '../../../hooks/RTK_Service/ordersApi';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import { DataGrid } from '@mui/x-data-grid';
import Swal from 'sweetalert2';

export default function Orders({ type, handelChangeOrderDetails, warehouses,warehouseId,handleChangeWarehouseId, totalPriceOfOrderProducts }) {
  const [selectedRow, setSelectedRow] = useState([]);
  var orderData = [];
  //const { isLoading, data, isError, error, isFetching, isSuccess } = useGetMarketplaceOrderDetailsQuery("processing");
  

  const { isLoading, data, isError, error, isFetching, isSuccess } = useGetMarketplaceOrdersByWarehouseQuery({ status: type, warehouseId: warehouseId });
  const [shipOrders, { isLoading: shipIsLoading, data: shipData, isError: shipIsError, error: shipError, isSuccess: shipIsSuccess }] = useShipMarketplaceOrderMutation();
  if (isError) {
    apiErrorManagemnt2(error);
  }
  if (shipIsError) {
    apiErrorManagemnt2(shipError);
  }
  const handleSelectRow = (singleOrderData) => {
    handelChangeOrderDetails(singleOrderData);
    // setSelectedRow(rowIndex);
  }
 
  if (isSuccess) {
    orderData = data?.orders
  }

  // Function to calculate the sum of prices in the array of objects
  const calculatePriceSum = (objects) => {
    return objects.reduce((acc, currentValue) => acc + (currentValue.solePrice * currentValue.quantity), 0);
  };

  const handleChange = (event) => {
    //setWarehouseId(event.target.value);
    handleChangeWarehouseId(event.target.value);
  };



  const columns = [
    { field: 'productName', headerName: 'Product Name', width: 200 },
    { field: 'sku', headerName: 'SKU', width: 200 },
    { field: 'quantity', headerName: 'Qty', width: 30 },
    {
      field: 'Total Amt',
      headerName: 'Total Amt',
      description: 'Total amount',
      sortable: false,
      width: 70,

      renderCell: (params) => {
        return (
          <Stack className="marketplaceInfo">
            {
              params.row.quantity * params.row.solePrice
            }
          </Stack>
        );
      },
    },
    { field: 'status', headerName: 'Status', width: 90 },

    {
      field: 'Action',
      checkboxSelection: false,
      headerName: 'Actions',
      description: 'Request to marketplace owner to add for selling product.',
      sortable: false,
      width: 90,
      border: "none",
      renderCell: (params) => {
        const onClick = (e) => {
          handleSelectRow(params.row)
        };

        return (
          <Stack>
            <div className='right sendRequestBtn'>
              <Button variant="contained" size="small" onClick={onClick}>open</Button>
            </div>
          </Stack>
        );
      },
    },

  ];

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => orderData.find((row) => row.orderProductId === id));
    setSelectedRow(selectedRowsData);
  };

  const shipSelectedOrders = () => {
    Swal.fire("Not Implemented!!","Shipping partner api not implemented yet to your warehouse.","info");
    if (selectedRow.length != 0) {
      //here process the shipping order

      // alert(JSON.stringify(selectedRow));
      // shipOrders(selectedRow).then((res) => {
      //   alert(JSON.stringify(res));
      // })
    }
  }

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <div className='spaceBetween'>
        <Button variant="contained" size="small" onClick={() => { shipSelectedOrders() }}>Ship selected orders</Button>
        <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
          <InputLabel id="demo-simple-select-helper-label">Select warehouse to get orders</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={warehouseId}
            label="Select warehouse to get orders"
            onChange={handleChange}
          >
            <MenuItem value="-1">
              <em>None</em>
            </MenuItem>
            {
              warehouses?.map(wData=>{
                return <MenuItem value={wData?.warehouseId}>{wData?.warehouseName}</MenuItem>
              })
            }
          </Select>
        </FormControl>

      </div>
      <DataGrid
        getRowId={(row) => row?.orderProductId}
        rows={orderData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        checkboxSelection
        disableSelectionOnClick={true}

        //onSelectionModelChange={}
        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
      />
    </div>
  )
}
