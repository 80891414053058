import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { checkSkuPresentOrNotInStock, getComboDataByIdDetails } from '../../../api/ComboData';
import Swal from 'sweetalert2';
import { hideLoading, showLoading } from '../../../store/loader';
import { Box } from '@mui/system';
import { Button, Grid, TextField } from '@mui/material';
import { useUpdateRequestData } from '../../../hooks/useUpdateRequestData';
import { UPDATE_TYPE_COMBODATA } from '../../../custome/constants';
import { useUpdateJsonComboData } from '../../../hooks/comboDataHooks';
import { useNavigate } from 'react-router-dom';
import { useUpdateJSONComboDataMutation } from '../../../hooks/RTK_Service/comboData';
import { apiErrorManagemnt2 } from '../../../api/Custom';

export default function UpdateComboData() {
    const updateCD = useUpdateRequestData(UPDATE_TYPE_COMBODATA);

    const [updateComboData, setUpdateComboData] = useState(updateCD!=null ? updateCD : null);
    const [showUpdateDataBtn, setShowUpdateDataBtn] = useState(false);
    //const jsonComboDataU = useUpdateJsonComboData(1);
    const [jsonComboDataU,{isError : jsonIsError, error:jsonError}] = useUpdateJSONComboDataMutation();
    const navigate = useNavigate();
    if(jsonIsError){
        apiErrorManagemnt2(jsonError);
    }
    // const getUpdateData = async (id) => {
    //     dispatch(showLoading());
    //     getComboDataByIdDetails(id).then((res) => {
    //         console.log(res);
    //         dispatch(hideLoading());
    //         if (res.status === 0) {
    //             alert(JSON.stringify(res.comboData))
    //             alert(JSON.stringify(updateCD))
    //             setUpdateComboData(res.comboData);
    //         } else if (res.status === 1) {
    //             Swal.fire("Error,Bad Input!!", res.message, 'error');
    //         }
    //     }, (err) => {
    //         dispatch(hideLoading())

    //     })
    // }

    const checkValidationComboData = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const key = data.get('key');
        const qty = data.get(`updateCDquantity${key}`)
        const sku = data.get(`updateCDsku${key}`)

        if (sku !== null && sku.length > 0) {
            //setLodder(true);
            checkSkuPresentOrNotInStock(sku).then((res) => {
                if (res.sku_present_in_stock) {
                    const upd_obj = JSON.parse(updateComboData?.jsonData)?.map((obj, tkey) => {
                        if (tkey == key) {
                            obj.sku = sku;
                            obj.quantity = qty
                        }
                        return obj;
                    })
                    setUpdateComboData({...updateComboData,jsonData:JSON.stringify(upd_obj)})
                    setShowUpdateDataBtn(true)
                }else{
                    Swal.fire("SKU NOT EXIST", sku+" is not present in stock details!!","info");
                }
            }, (err) => {

            })
        }
    }

    const saveUpdateComboData = ()=>{
        setShowUpdateDataBtn(false)
        jsonComboDataU(updateComboData).then((res)=>{
            navigate("/dashboard/combo_data")
            Swal.fire({
                icon: 'success',
                toast: true,
                position: 'bottom-right',
                text: "combo data successfully updated.",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true
            })
        })
        
    }

    // useEffect(() => {
    //     getUpdateData(1);
    // }, [])
    return (

        <>
            <div className="text-center text-muted mt-2">
                <h2>UPDATE COMBO DATA</h2>
                {
                    showUpdateDataBtn && <><Button
                        color="success"
                        onClick={saveUpdateComboData}
                        style={{width:"200px"}}
                        variant="contained"
                        sx={{ mt:3, py: 2 }}
                    >
                        Save Updated Data
                    </Button><br/>
                    <small className='text-danger'><b>Note : </b>please click on save update data button when make any changes.</small>
                    </>
                }
            </div>
            {
                updateComboData && <>
                    {JSON.parse(updateComboData?.jsonData)?.map((data, key) => {
                        return (
                            <Box className='center' mt={3} component="form" onSubmit={checkValidationComboData}>
                                <Grid container xs={12} sm={6} md={7} >
                                    <Grid item xs={12} sm={5} >
                                        <TextField
                                            autoComplete="given-name"
                                            name={`updateCDsku${key}`}
                                            required
                                            fullWidth
                                            id={`updateCDsku${key}`}
                                            label="sku"
                                            multiline
                                            defaultValue={data?.sku}
                                            sx={{  mr:2 }}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5} >
                                        <TextField
                                            autoComplete="given-name"
                                            name={`updateCDquantity${key}`}
                                            required
                                            fullWidth
                                            id={`updateCDquantity${key}`}
                                            label="Quantity"
                                            multiline
                                            defaultValue={data?.quantity}
                                            sx={{ mx:1  }}

                                        />
                                    </Grid>
                                    <input type='hidden' name="key" value={key} />
                                    <input type="hidden" name="comboDataId" value={data.comboDataId}></input> 
                                    <Grid item xs={12} sm={2} >
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ ml:2,mb: 2, py: 2 }}
                                        >
                                            Change
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>

                        )
                    })}
                </>
                    

            }
        </ >

    )
}
