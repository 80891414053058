import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FormControlLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
{
    /***
     * This page is not in use
     * 
     * 
     */
}


export default function AddProduct() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [imageOption, setImageOption] = React.useState("cloudUrls");
    const [psku, setPsku] = React.useState(null);
    const [productData, setProductData] = React.useState(null);
    const [variationData, setVariationData] = React.useState(null);
    const [ulrArray, setUrlArray] = React.useState([]);
    const [urlCounter, setUrlCounter] = React.useState(1);

    const [titleError,setTitleError] = React.useState(null);
    const [customUrlError,setCustomeUrlError] = React.useState(null);
    const [qtyError,setQtyError] = React.useState(null);
    const [priceError,setPriceError] = React.useState(null);
    const [descError,setDescError] = React.useState(null);
    const [skuError, setSkuError] = React.useState(null);
    const [urlError, setUrlError] = React.useState(null)


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSkuSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const sku = data.get("PSKU");
        if (sku != null && sku?.length > 0) {
            setPsku(sku);
            setSkuError(null)
            handleNext();
        } else {
            setSkuError("sku is required !!");
        }
    }

    const handleProductSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const ptitle = data.get("ptitle");
        const pdqty = data.get("pdqty");
        const pprice = data.get("pdprice");
        const pdesc= data.get("pdesc")

        if(ptitle==null || ptitle.length==0){
            setTitleError("product title is required !!");
            return;
        }else if(pdqty==null || pdqty==0){
            setQtyError("product default quantity is required !!");
            setTitleError(null);
            return;
        }else if(pprice==null || pprice==0){
            setPriceError("product price is required !!")
            setQtyError(null);
            setTitleError(null);
            return;
        }else if(pdesc ==null || pdesc.length==0){
            setDescError("product description is required !!")
            setPriceError(null)
            setQtyError(null);
            setTitleError(null);
            return;
        }else{
            setDescError(null);
            setPriceError(null);
            setQtyError(null);
            setTitleError(null);
            const obj ={
                productTitle : ptitle,
                productPrice : pprice,
                productQuantity : pdqty,
                productDescription : pdesc
            }
            setProductData(obj);
            handleNext();
            return;
        }
    }

    const handleAddCustomImgUrls = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const imgType = data.get("imgUrlType");
        const imgUrl = data.get("imageUrl");
        if (imgUrl.length > 0) {
            const obj = {
                id: urlCounter,
                type: imgType,
                url: imgUrl
            }
            setUrlArray(current => [...current, obj]);
            setUrlCounter(urlCounter + 1);
            setUrlError(null);
            setCustomeUrlError(null);
        } else {
            setUrlError("image url is required !!")

        }
        return false;
    }

    const handleRemoveImgUrl = (id) => {
        const newArray = ulrArray.filter((item) => item.id != id);
        setUrlArray(newArray);
    }

    const handleSubmitImgUrls=()=>{
        if(imageOption==="cloudUrls"){
            handleNext();
        }else if(imageOption==="customUrls"){
            if(ulrArray==null || ulrArray.length===0){
                setCustomeUrlError("No custom url present, add minimum one product image url !!")
            }else{
                setCustomeUrlError(null);
                handleNext();
            }
        }
    }

    const handleVariationSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setVariationData(data);
        handleNext();
    }

    const handleFinalSaveProduct = () => {
        alert(psku + " " + JSON.stringify(productData) + " " + JSON.stringify(ulrArray) + " " + JSON.stringify(variationData));
    }

    return (

        <Box className='center' sx={{ mt: 3 }}>
            {console.warn("render")}
            <Grid container xs={12} sm={6} md={7} >
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key={'SKU'}>
                        <StepLabel>
                            {activeStep === 0 ? <b className="text-primary"> SKU</b> : 'SKU'}
                        </StepLabel>
                        <StepContent>
                            <div style={{ width: "600px" }}>
                                <Box component="form" noValidate onSubmit={handleSkuSubmit} sm={12} mt={12} sx={{ mt: 2 }}>
                                    <Grid className='' container xs={12} >
                                        <TextField
                                            autoComplete="given-name"
                                            name="PSKU"
                                            error={skuError != null ? true : false}
                                            helperText={skuError != null ? skuError : ''}
                                            required
                                            fullWidth
                                            id="PSKU"
                                            label="SKU"
                                            multiline
                                            defaultValue={psku ? psku : ''}
                                            autoFocus
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid>
                                    <Box className="right" sx={{ mb: 2 }}>
                                        <div>
                                            <Button
                                                variant="contained"
                                                type='submit'
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {'Continue'}
                                            </Button>
                                        </div>
                                    </Box>
                                </Box>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key={'FILL PRODUCT DETAILS'}>
                        <StepLabel>
                            {activeStep === 1 ? <b className="text-primary">FILL PRODUCT DETAILS</b> : 'FILL PRODUCT DETAILS'}
                        </StepLabel>
                        <StepContent>
                            <div style={{ width: "600px" }}>
                                <Box className=' ' component="form" noValidate onSubmit={handleProductSubmit} sm={12} mt={12} sx={{ mt: 2 }}>
                                    <Grid className='' container xs={12} >
                                        <TextField
                                            autoComplete="given-name"
                                            name="ptitle"
                                            error={titleError !=null ?true:false}
                                            helperText={titleError!=null ? titleError : ''}
                                            required
                                            fullWidth
                                            id="ptitle"
                                            label="Ptoduct title"
                                            multiline
                                            defaultValue={productData!=null ? productData?.productTitle : ''}
                                            autoFocus
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid>
                                    <Grid className='' container xs={12} >
                                        <TextField
                                            autoComplete="given-name"
                                            name="pdqty"
                                            error={qtyError !=null ?true:false}
                                            helperText={qtyError!=null ? qtyError : ''}
                                            required
                                            fullWidth
                                            id="pdqty"
                                            label="Ptoduct default quantity"
                                            multiline
                                            defaultValue={productData!=null ? productData?.productQuantity : ''}
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid>
                                    <Grid className='' container xs={12} >
                                        <TextField
                                            autoComplete="given-name"
                                            name="pdprice"
                                            error={priceError !=null ?true:false}
                                            helperText={priceError!=null ? priceError : ''}
                                            required
                                            fullWidth
                                            id="pdprice"
                                            label="Ptoduct default price"
                                            multiline
                                            defaultValue={productData!=null ? productData?.productPrice : ''}
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid>
                                    <Grid className='' container xs={12} >
                                        <TextField
                                            autoComplete="given-name"
                                            name="pdesc"
                                            error={descError !=null ?true:false}
                                            helperText={descError!=null ? descError : ''}
                                            required
                                            fullWidth
                                            id="pdesc"
                                            label="Ptoduct description"
                                            multiline
                                            defaultValue={productData!=null ? productData?.productDescription : ''}
                                            rows={4}
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid>
                                    <Box className="right" sx={{ mb: 2 }}>
                                        <div>
                                            <Button
                                                variant="contained"
                                                type='submit'
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {'Continue'}
                                            </Button>
                                            <Button
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                    </Box>
                                </Box>
                            </div>

                        </StepContent>
                    </Step>
                    <Step key={'SUBMIT IMAGE URLS'}>
                        <StepLabel>
                            {activeStep === 2 ? <b className="text-primary">SUBMIT IMAGE URLS</b> : 'SUBMIT IMAGE URLS'}
                        </StepLabel>
                        <StepContent>
                            <div style={{ width: "800px" }}>
                                <Box className='' sm={12} mt={12} sx={{ mt: 2 }}>
                                    <RadioGroup
                                        aria-labelledby="demo-error-radios"
                                        value={imageOption}
                                        onChange={(e) => { setImageOption(e.target.value) }}
                                    >
                                        <FormControlLabel value="cloudUrls" control={<Radio />} label="Structured URL(This is preferred method. You need upload images to cloud with naming convention of common SKU_variation.jpg value, in this can you can avoid entering the image urls , application can take images directly from the cloud URL mentioned on Profile Settings)" />
                                        <FormControlLabel value="customUrls" control={<Radio />} label="Custom URL (You would need to provide all the URLS for the Images here)" />
                                    </RadioGroup>
                                    {imageOption === 'customUrls' &&
                                        <Box className='card p-3' component="form" noValidate onSubmit={handleAddCustomImgUrls} sm={12} mt={12} sx={{ mt: 2 }}>
                                            {customUrlError && <span className="text-danger my-1">{customUrlError}</span> }
                                            <Grid className='' container xs={12} >
                                                <TextField
                                                    autoComplete="given-name"
                                                    name="imageUrl"
                                                    error={urlError != null ? true : false}
                                                    required
                                                    fullWidth
                                                    id="productImageUrl"
                                                    label="Product image url"
                                                    helperText={urlError != null ? urlError : ''}
                                                    multiline
                                                    autoFocus
                                                    sx={{ mb: 2 }}
                                                />
                                            </Grid>
                                            <RadioGroup
                                                row
                                                defaultValue={"O"}
                                                name='imgUrlType'
                                            >
                                                <FormControlLabel value="M" control={<Radio />} label="Main" />
                                                <FormControlLabel value="O" control={<Radio />} label="Other" />
                                            </RadioGroup>
                                            <Box sx={{ mb: 3 }}>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        type='submit'
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        ADD URL
                                                    </Button>
                                                </div>
                                            </Box>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>#ID</TableCell>
                                                            <TableCell align="left">URL</TableCell>
                                                            <TableCell align="left">TYPE</TableCell>
                                                            <TableCell align="left">ACTION</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {ulrArray?.map((item) =>
                                                            <TableRow
                                                                key={item?.id + "url"}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">{item?.id}</TableCell>
                                                                <TableCell component="th" scope="row">{item?.url}</TableCell>
                                                                <TableCell component="th" scope="row">{item?.type}</TableCell>
                                                                <TableCell component="th" scope="row"><button onClick={() => { handleRemoveImgUrl(item?.id) }} className="btn btn-sm btn-danger">Remove</button></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table></TableContainer>
                                        </Box>
                                    }
                                </Box>
                            </div>
                            <Box className="right" sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleSubmitImgUrls}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {'Continue'}
                                    </Button>
                                    <Button
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step key={"variation"}>
                        <StepLabel
                            optional={
                                <Typography variant="caption">Last step</Typography>
                            }
                        >
                            {activeStep === 3 ? <b className="text-primary">VARIATIONS (OPTIONAL)</b> : 'VARIATIONS (OPTIONAL)'}
                        </StepLabel>
                        <StepContent>
                            <div style={{ width: "600px" }}>
                                <Box className=' ' component="form" noValidate onSubmit={handleVariationSubmit} sm={12} mt={12} sx={{ mt: 2 }}>
                                    <Grid className='' container xs={12} >
                                        <TextField
                                            autoComplete="given-name"
                                            name="ptitle"
                                            required
                                            fullWidth
                                            id="ptitle"
                                            label="Ptoduct title"
                                            multiline
                                            autoFocus
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid>
                                    <Box sx={{ mb: 2 }}>
                                        <div>
                                            <Button
                                                variant="contained"
                                                type='submit'
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {'Finish'}
                                            </Button>
                                            <Button
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                    </Box>
                                </Box>
                            </div>
                        </StepContent>
                    </Step>

                </Stepper>
                {activeStep === 4 && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>All steps completed - save the product.</Typography>
                        <Button fullWidth onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Reset
                        </Button>
                        <Button fullWidth onClick={handleFinalSaveProduct} sx={{ mt: 1, mr: 1 }}>
                            Save product
                        </Button>
                    </Paper>
                )}
            </Grid>
        </Box>
    );
}