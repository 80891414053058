import React from 'react'
import Sheduler from '../../components/sheduler/Sheduler'
import { getToken } from '../../custome/action';
import Service from '../../components/service/Service';
import Contact from '../../components/contact/Contact';
import HeroSection from '../../components/heroSection/HeroSection';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import About from '../../components/about/About';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Section1 from '../../components/highliterSection/Section1';

export default function Home() {
    const navigate = useNavigate();
    const token = getToken();
    if (token !== null) {
        console.log("navigation")
        navigate("/dashboard")
    }

    return (
        <div className='bg-light'>
            <Helmet>

                <meta
                    name="description"
                    //content="provides multi-channel inventory Management software for E-commerce partners and Sellers in India. It helps you to track orders, control stock, and manage your business."
                    content="Manageecom provides an Inventory management software solution for the e-commerce industry that helps you to track orders, control stock, and manage your business."
                />

                {/* multiple link elements */}
                <link rel="canonical" href={process.env.REACT_APP_MANAGEECOM_BASE_URL + "/"} />

            </Helmet>


            <Header />
            <HeroSection />
            <div className='container mt-nav' >
                <Section1/>
                <Sheduler />

                <div className="text-center title-section">
                    <h3 className='uppercase mainColor'>features</h3>
                    <h1 className='uppercase mainColor2'>Our Features and services.</h1>
                </div>
                <Service />

                <About />

                <div className="text-center title-section">
                    <h1 className='uppercase mainColor2'>Contact us</h1>
                </div>
                <Contact />

            </div>
            <Footer />
        </div>
    )
}
