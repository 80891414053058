import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { useGetSellerDetailsQuery } from '../../../hooks/RTK_Service/userLoginRegister';
import PrintIcon from '@mui/icons-material/Print';
import { useState } from 'react';

const Invoice = () => {
    const location = useLocation();
    const order = location.state || {};
    const [isCodPrepaidOrder, setIsCodPrepaidOrder] = useState((order?.marketplaceOrder?.codPrepaidAmountPercentage > 0 && order?.marketplaceOrder?.codPrepaidPaymentStatus === "success"));

    const sellerDetails = useGetSellerDetailsQuery();

    const codPrepaidAmt = isCodPrepaidOrder ? ((order?.quantity * (order?.solePrice) * (order?.marketplaceOrder?.codPrepaidAmountPercentage / 100)).toFixed(2)) : 0;
    if (sellerDetails?.isSuccess) {
        //
    }

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Container>
            <Button
                variant="contained"
                color="primary"
                onClick={handlePrint}
                sx={{ marginBottom: 2, marginTop: 2 }}
            >
                <PrintIcon />&nbsp; Print Invoice
            </Button>
            <Box ref={componentRef} sx={{ padding: 3, border: '1px solid #ccc' }}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="h4"><b>INVOICE</b></Typography>
                        <Typography variant="h6">{sellerDetails?.data?.user?.companyName}</Typography>
                        <Typography>{sellerDetails?.data?.user?.address}</Typography>
                        <Typography>{sellerDetails?.data?.user?.pincode}</Typography>
                        <Typography>Phone: {sellerDetails?.data?.user?.phone}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                        <Typography variant="h6">Bill To:</Typography>
                        <Typography>{order?.marketplaceOrder?.buyerName || 'Customer Name'}</Typography>
                        <Typography>{order?.marketplaceOrder?.orderAddress || 'Customer Address'}</Typography>
                        <Typography>{order?.marketplaceOrder?.state + " " + order?.marketplaceOrder?.pincode || 'Customer City, State, ZIP'}</Typography>
                        <Typography>Phone: {order?.marketplaceOrder?.buyerContact || 'Customer Phone'}</Typography>
                        <Typography>Payment Type : {order?.marketplaceOrder?.orderPaymentType}</Typography>
                        <Typography>Payment Status:{order?.marketplaceOrder?.paymentStatus}</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 3 }} />
                <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                    <Grid item xs={6}>
                        <Typography>
                            <strong>Invoice Number:</strong> {"INV-" + order?.orderProductId || 'null'}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                        <Typography>
                            <strong>Order Date:</strong> {new Date(order?.marketplaceOrder?.orderDtTime).toLocaleString()}
                        </Typography>
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell align='right'>Quantity</TableCell>
                                <TableCell align="right">Unit Price</TableCell>
                                <TableCell align='right'>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <span><b>Title :</b> {order?.productName}</span><br />
                                    <span><b>SKU : {order?.sku}</b></span><br />
                                </TableCell>
                                <TableCell align="right">{order?.quantity}</TableCell>
                                <TableCell align="right">₹{(order?.solePrice - (order?.taxAmt/order?.quantity))}</TableCell>
                                <TableCell align="right">₹{order?.quantity * (order?.solePrice - (order?.taxAmt/order?.quantity))}</TableCell>
                            </TableRow>



                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={3} align="right"><b>Sub Total(+) :</b></TableCell>
                                <TableCell align="right" className='text-success'><b>₹{order?.quantity * (order?.solePrice - (order?.taxAmt/order?.quantity))}</b></TableCell>
                            </TableRow>

                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={3} align="right"><b>Total Tax(+) :</b></TableCell>
                                <TableCell align="right" className='text-success'><b>₹{order.taxAmt}</b></TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={3} align="right"><b>Shipping charges(+) :</b></TableCell>
                                <TableCell align="right">{(order?.marketplaceOrder?.totalProducts > 0 ? (order?.marketplaceOrder?.shippingFee / order?.marketplaceOrder?.totalProducts) : 0).toFixed(2)}</TableCell>
                            </TableRow>
                            {
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={3} align="right"><b>COD Prepaid Amt(-) :</b></TableCell>
                                    <TableCell align="right" className='text-success'><b>₹{codPrepaidAmt}</b></TableCell>
                                </TableRow>
                            }

                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell colSpan={3} align="right"><b>Total </b> (sub_total + total_tax + shipping_charge - cod_prepaid_amt)<b>:</b></TableCell>
                                <TableCell align="right" className='text-success'><b>₹{(((order?.quantity * (order?.solePrice)) - codPrepaidAmt) + (order?.marketplaceOrder?.totalProducts > 0 ? (order?.marketplaceOrder?.shippingFee / order?.marketplaceOrder?.totalProducts) : 0) ).toFixed(2) }</b></TableCell>
                            </TableRow>



                        </TableBody>
                    </Table>
                </TableContainer>

                <Divider sx={{ my: 3 }} />
                <Box sx={{ textAlign: 'center', marginTop: 4 }}>
                    <Typography>Thank you for your business!</Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default Invoice;
