import React, { useState } from 'react'
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Fade, Menu, MenuItem, Paper } from '@mui/material';

export default function SearchPannel({ handleSearch ,clearSearch, keyArray, searchedValue}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchBy, setSearchBy] = useState(keyArray[0]);
    const [searchvalue, setSearchValue] = useState(searchedValue);
    const open = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearchBtn = () => {
        handleSearch(searchBy, searchvalue);
    }

    const onKeyDownHandler = e => {
        if (e.keyCode === 13) {
          e.preventDefault(); 
          handleSearchBtn();
        }
    };

    return (
        <div>
            <Paper
                component="form"
                sx={{ p: '2px', display: 'flex', alignItems: 'center', width: '100%', maxWidth: 600, margin: '0 auto' }}
                className="search-panel"
            >
                <div>
                    <Button
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleMenuClick}
                    >
                        <MenuIcon />
                    </Button>
                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        {
                            keyArray?.map(item=>{
                                return(
                                <MenuItem selected={searchBy === {item} ? true : false} onClick={() => { setSearchBy(item); handleClose() }}>search by {item}</MenuItem>
                                );
                            })
                        }
                       
                    </Menu>
                </div>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={`Product search by ${searchBy}`}
                    inputProps={{ 'aria-label': 'search product' }}
                    onChange={(event) => { setSearchValue(event.target.value) }}
                    onKeyDown={onKeyDownHandler}
                    defaultValue={searchedValue}
                />
                <IconButton onClick={() => { handleSearchBtn() }} type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
                <IconButton onClick={() => { clearSearch() }} type="button" sx={{ p: '10px' }} aria-label="search">
                    <RefreshIcon />
                </IconButton>
            </Paper>
        </div>
    )
}
