import React, { useState } from 'react'
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Fade, Menu, MenuItem, Paper } from '@mui/material';

export default function SearchStock({handleSearch}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchBy,setSearchBy] = useState("SKU");
    const [searchvalue,setSearchValue]= useState(null);

    const open = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearchBtn=()=>{
        handleSearch(searchBy,searchvalue);
    }

    const onKeyDownHandler = e => {
        if (e.keyCode === 13) {
          e.preventDefault(); 
          handleSearchBtn();
        }
    };
    

    return (
        <div>
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%" }}
            >
                <div>
                    <Button
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleMenuClick}
                    >
                        <MenuIcon />
                    </Button>
                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <MenuItem selected={searchBy==="SKU"?true:false} onClick={()=>{setSearchBy("SKU");handleClose()}}>search by sku</MenuItem>
                    </Menu>
                </div>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={`Stock search by ${searchBy}`}
                    inputProps={{ 'aria-label': 'search stock ' }}
                    onChange={(event)=>{setSearchValue(event.target.value)}}
                    onKeyDown={onKeyDownHandler}
                />
                <IconButton onClick={()=>{handleSearchBtn()}} type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Paper>
        </div>
    )
}
