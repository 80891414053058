import React from 'react'
import management from '../../files/imgs/management.svg'
import processing from '../../files/imgs/processing.svg'
import solution from '../../files/imgs/solution.svg'
import './service.css'
import { Link } from 'react-router-dom'
export default function Service() {
  return (
    <div>
      <div className="row">

        <div className="col-sm-12 col-md-4">
          <div className="card service-card h-fixed card-shadow mt-1 pb-4">
            <div className="text-center">
              <img className='pt-0 mt-0 service-img' src={solution} alt="management" />
            </div>
            <div className='text-center mt-1 mb-2'>
              <h4 className='mainColor uppercase'>PROVIDE <br /><b>SALES</b></h4>
            </div>
            <div className="text-center text-muted my-2 px-2">
              <p>We provide sales for your products through our various ecommerce channels , Facebook and Whatsapp groups...</p>
            </div>

            <div className='service-information-pannel'>
              <div className='text-center mt-1 mb-2'>
                <h4 className='mainColor uppercase'>PROVIDE <br /><b>SALES</b></h4>
              </div>
              <div className="text-center text-light  mt-2 px-3">
                <p className='text-center'>We provide sales for your products through our various ecommerce channels , Facebook and Whatsapp groups.Anyone can take a ready marketplace software from nuviosellers.com and choose sellers and start selling their products.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="card service-card h-fixed card-shadow mt-1 pb-4">
            <div className="text-center">
              <img className='pt-0 mt-0 service-img' src={management} alt="management" />
            </div>
            <div className='text-center mt-1 mb-2'>
              <h4 className='mainColor uppercase'>Inventory <br /><b>management</b></h4>
            </div>
            <div className="text-center text-muted my-2 px-2">
              <p>Warehouse management for online sellers can be a
                problematic affair because of its various intricacies. You might
                feel puzzled at a number of...</p>
            </div>
            <div className='service-information-pannel'>
              <div className='text-center mt-1 mb-2'>
                <h4 className='mainColor uppercase'>Inventory <br /><b>management</b></h4>
              </div>
              <div className="text-center text-light  mt-2 px-3">
                <p className='text-justified'>Warehouse management for online sellers can be a
                  problematic affair because of its various intricacies. You might
                  feel puzzled at a number of instances, as managing the inventory
                  of e-commerce websites take a lot of effort and attention. It is
                  also possible for you to feel difficulty in categorizing the
                  products of different websites. However, with our multi-channel
                  inventory management software, you will be able to do this
                  process with a significant amount of ease. You will not have to
                  worry about anything else in this direction.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="card service-card h-fixed card-shadow mt-1 pb-4">
            <div className="text-center">
              <img className='pt-0 mt-0 service-img' src={processing} alt="management" />
            </div>
            <div className='text-center mt-1 mb-2'>
              <h4 className='mainColor uppercase'>Order <br /><b>Processing</b></h4>
            </div>
            <div className="text-center text-muted my-2 px-2">
              <p>The biggest problem for a multi-e-commerce website owner
                is time management. One has to make sure that every website
                functions properly...</p>            </div>
            <div className='service-information-pannel'>
              <div className='text-center mt-1 mb-2'>
                <h4 className='mainColor uppercase'>Order <br /><b>Processing</b></h4>
              </div>
              <div className="text-center text-light  mt-2 px-3">
                <p className='text-justified'>The biggest problem for a multi-e-commerce website owner
                  is time management. One has to make sure that every website
                  functions properly and does not fall off the charts. There are
                  many service providers present in the market claim to solve this
                  difficulty but very few of them succeed. Here at ManageEcom, we
                  consider every factor. For example, the introduction of Goods
                  and Services Tax in India led to a significant amount of hassle
                  to e-commerce site owners. We cater to those needs as well, and
                  provide services such as GST filing for online sellers and GST
                  compliance for online sellers. We also manage payment
                  reconciliation for online seller. Therefore, you should not
                  worry much in this regard. You will be able to tackle almost
                  every difficulty of this field with our help.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='right'>
          <Link to="/service" className='px-5 py-2 mt-3 text-light' style={{ backgroundColor: 'var(--mainColor2)', border: 'none', borderRadius: '20px', textDecoration: 'none' }}>Read More</Link>
        </div>
      </div>

    </div>
  )
}
