import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import StepOne from './product_steps/StepOne';
import StepTwo from './product_steps/StepTwo';
import StepThree from './product_steps/StepThree';
import StepFour from './product_steps/StepFour';
// import { saveJobAndBatchExcelData2, saveProduct, saveProductData, updateProductData } from '../../../api/product';
import { useUpdateRequestData } from '../../../hooks/useUpdateRequestData';
import { ADD_PRODUCT_PATH, DASHBOARD_BASE_PATH, MANAGE_PRODUCT_PATH, MANAGE_STOCK_PATH, UPDATE_TYPE_PRODUCT } from '../../../custome/constants';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { removeUpdateDetails } from '../../../store/updateData';
// import { getAllStockDetailsByWarehouseId } from '../../../api/manageStock';
import { useSaveProductMutation, useUpdateProductMutation } from '../../../hooks/RTK_Service/productApi';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import Swal from 'sweetalert2';
import { useGetAllStockDetailsByWarehouseIdQuery } from '../../../hooks/RTK_Service/stockApi';

const steps = ['Select category and sku', 'Fill product details', 'Add bullet points', "Select Images"];

export default function AddProduct2() {
    const [activeStep, setActiveStep] = useState(0);
    const [stepOneData, setStepOneData] = useState(null);
    const [stepTwoData, setStepTwoData] = useState(null);
    const [stepThreeData, setStepThreeData] = useState(null);
    const [stepFourData, setStepFourData] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const warehouse = useSelector((state) => state.warehouse.warehouse)

    const options = [];

    const allStockDetails = useGetAllStockDetailsByWarehouseIdQuery(warehouse?.warehouseId);
    if (allStockDetails?.isSuccess) {
        if (allStockDetails?.data?.status === 0) {
            allStockDetails?.data?.stocks?.map(data => {
                options.push(data?.sku);
            });
            allStockDetails?.data?.comboData?.map(data => {
                options.push(data?.comboSku);
            });
        }
    }

    const [updateProductMutation, { isError: updateIsError, error: updateError, isSuccess: updateProductSuccess }] = useUpdateProductMutation();
    const [saveProductMutation, { isError: saveIsError, error: saveError, isSuccess: saveProductSuccess }] = useSaveProductMutation();

    if (updateIsError) {
        apiErrorManagemnt2(updateError)
    }
    if (saveIsError) {
        apiErrorManagemnt2(saveError)
    }

    if (saveProductSuccess) {
        Swal.fire("Success", "Product save successfully.", "success")
    }
    if (updateProductSuccess) {
        Swal.fire("Success", "Update product data successfully.", "success")
    }


    // useEffect(() => {
    //     const getAllSku = async () => {
    //         if (warehouse) {
    //             getAllStockDetailsByWarehouseId(warehouse?.warehouseId).then(res => {
    //                 if (res?.status === 0) {
    //                     res?.stocks?.map(data => {
    //                         options.push(data?.sku);
    //                     });
    //                 }
    //             })
    //         }
    //     }
    //     getAllSku();
    // }, warehouse?.warehouseId)


    const updateData = useUpdateRequestData(UPDATE_TYPE_PRODUCT);
    const isStepOptional = (step) => {
        return step === 9;
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setStepOneData(null);
        setStepTwoData(null);
        setStepThreeData(null);
        setStepFourData(null);
        setActiveStep(0);
    };

    const handleSaveProductDetails = () => {
        const data = new FormData();
        var arrangBulletPoints = "";
        stepThreeData?.forEach(element => {
            arrangBulletPoints += "##" + element.point;
        });
        var obj = {
            brand: stepTwoData?.brand,
            color: stepTwoData?.color,
            description: stepTwoData?.description,
            material: stepTwoData?.material,
            price: stepTwoData?.mrp,
            sale_price: stepTwoData?.salePrice,
            title: stepTwoData?.title,
            hsnCode: stepTwoData?.hsn,
            categoryId: stepOneData?.category,
            bulletpoints: arrangBulletPoints.substring(2),
            keywords: stepTwoData?.keywords,
            minimumOrderQuantity:stepTwoData?.minimumOrderQuantity,
            sku: stepOneData?.sku,
            warehouseId: warehouse?.warehouseId,

        }
        data.append("main", stepFourData?.main);
        data.append("img1", stepFourData?.sub1);
        data.append("img2", stepFourData?.sub2);
        data.append("img3", stepFourData?.sub3);

        if (updateData != null) {
            obj = { ...obj, productRefId: updateData.productRefId }
            data.append("product_info", JSON.stringify(obj))
            updateProductMutation(data).then((res) => {
                if (res?.data?.status === 0) {
                    dispatch(removeUpdateDetails());
                    navigate(DASHBOARD_BASE_PATH + MANAGE_PRODUCT_PATH)
                }
            })

        } else {
            data.append("product_info", JSON.stringify(obj))
            saveProductMutation(data).then((res) => {
                if (res?.data?.status === 0)
                    navigate(DASHBOARD_BASE_PATH + MANAGE_PRODUCT_PATH)

            })
        }

    }

    return (
        <>
            {
                updateData != null &&
                <div className="mt-3 text-center text-muted">
                    <p>{updateData?.title}</p>
                </div>

            }

            <Box className="center mt-3 mb-5">
                {
                    useSelector((state) => state.warehouse.warehouse_is_selected) ?
                        <Box sx={{ width: '90%' }}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    if (isStepOptional(index)) {
                                        labelProps.optional = (
                                            <Typography variant="caption">Optional</Typography>
                                        );
                                    }

                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {activeStep === 4 ? (
                                <React.Fragment>
                                    <Box className="center my-5">
                                        <Grid className='center' container xs={12} sm={6} md={6} >
                                            <Box className="card p-3 m-3">
                                                <Typography sx={{ mt: 2, mb: 1 }}>
                                                    All steps completed - you&apos;re able save product!!
                                                </Typography>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                    <Box sx={{ flex: '1 1 auto' }} />
                                                    <Button className='mx-1' variant="outlined" onClick={handleReset}>Reset</Button>
                                                    <Button onClick={handleSaveProductDetails} variant='contained' >Save product details</Button>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {
                                        activeStep === 0 && <StepOne stepOneData={stepOneData} setStepOneData={setStepOneData} handleNext={handleNext} handleBack={handleBack} options={options} warehouseName={warehouse.warehouseName} />
                                    }

                                    {
                                        activeStep === 1 && <StepTwo stepTwoData={stepTwoData} setStepTwoData={setStepTwoData} handleNext={handleNext} handleBack={handleBack} />
                                    }

                                    {
                                        activeStep === 2 && <StepThree stepThreeData={stepThreeData} setStepThreeData={setStepThreeData} handleNext={handleNext} handleBack={handleBack} />
                                    }
                                    {
                                        activeStep === 3 && <StepFour stepFourData={stepFourData} setStepFourData={setStepFourData} handleNext={handleNext} handleBack={handleBack} />
                                    }
                                </React.Fragment>
                            )}
                        </Box>
                        :
                        <div className='center'>
                            <div className="alert alert-warning p-4 my-5">
                                <h3><i className="fa-solid fa-circle-exclamation fa-fade fa-1x" style={{ color: "#e32b16" }}></i>You have not selected any warehouse, please select warehouse first !!!</h3>
                                <div className="right my-3">
                                    <Link to={DASHBOARD_BASE_PATH + MANAGE_STOCK_PATH + "?tpath=" + ADD_PRODUCT_PATH} className="btn btn-outline-danger">select warehouse</Link>
                                </div>
                            </div>
                        </div>
                }
            </Box>
        </>
    );
}