import React, { useEffect } from 'react'
import "../../components/service/service.css"
import { Container } from '@mui/material'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import management from '../../files/imgs/management.svg'
import processing from '../../files/imgs/processing.svg'
import solution from '../../files/imgs/solution.svg'
import gst from '../../files/imgs/Calculator-bro.svg'
import cancelation from '../../files/imgs/Cancel.svg'
import returnO from '../../files/imgs/orderM.svg'
import { Helmet } from 'react-helmet'

export default function ServicePage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Helmet>
                <title>Service - Manageecom Solutions</title>
                <meta name="description" content="provides multi-channel inventory Management software for E-commerce partners and Sellers in India. It helps you to track orders, and manage your business." />
                {/* multiple link elements */}
                <link rel="canonical" href={process.env.REACT_APP_MANAGEECOM_BASE_URL + "/service"} />
            </Helmet>
            <Header />
            <div className='back-small-div' />

            <Container className="back-small-div-container mb-4">
                <div className='center mt-5'>
                    <div >
                        <h1 className="text-light text-center"><b>FEATURES AND SERVICES</b></h1>

                    </div>
                </div>
            </Container>

            <div className='container mt-5'>
                <div className="row">
                    
                    <div className="col-sm-12 col-md-4">
                        <div className="card service-card h-fixed card-shadow mt-1 pb-4">
                            <div className="text-center">
                                <img className='pt-0 mt-0 service-img' src={solution} alt="management" />
                            </div>
                            <div className='text-center mt-1 mb-2'>
                                <h4 className='mainColor uppercase'>PROVIDE <br /><b>SALES</b></h4>
                            </div>
                            <div className="text-center text-muted my-2 px-2">
                                <p>We provide sales for your products through our various ecommerce channels , Facebook and Whatsapp groups...</p>
                            </div>

                            <div className='service-information-pannel'>
                                <div className='text-center mt-1 mb-2'>
                                    <h4 className='mainColor uppercase'>PROVIDE <br /><b>SALES</b></h4>
                                </div>
                                <div className="text-center text-light  mt-2 px-3">
                                    <p className='text-center'>We provide sales for your products through our various ecommerce channels , Facebook and Whatsapp groups.Anyone can take a ready marketplace software from nuviosellers.com and choose sellers and start selling their products.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <div className="card service-card h-fixed card-shadow mt-1 pb-4">
                            <div className="text-center">
                                <img className='pt-0 mt-0 service-img' src={management} alt="management" />
                            </div>
                            <div className='text-center mt-1 mb-2'>
                                <h4 className='mainColor uppercase'>Inventory <br /><b>management</b></h4>
                            </div>
                            <div className="text-center text-muted my-2 px-2">
                                <p>Warehouse management for online sellers can be a
                                    problematic affair because of its various intricacies. You might
                                    feel puzzled at a number of...</p>
                            </div>
                            <div className='service-information-pannel'>
                                <div className='text-center mt-1 mb-2'>
                                    <h4 className='mainColor uppercase'>Inventory <br /><b>management</b></h4>
                                </div>
                                <div className="text-center text-light  mt-2 px-3">
                                    <p className='text-justified'>Warehouse management for online sellers can be a
                                        problematic affair because of its various intricacies. You might
                                        feel puzzled at a number of instances, as managing the inventory
                                        of e-commerce websites take a lot of effort and attention. It is
                                        also possible for you to feel difficulty in categorizing the
                                        products of different websites. However, with our multi-channel
                                        inventory management software, you will be able to do this
                                        process with a significant amount of ease. You will not have to
                                        worry about anything else in this direction.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <div className="card service-card h-fixed card-shadow mt-1 pb-4">
                            <div className="text-center">
                                <img className='pt-0 mt-0 service-img' src={processing} alt="management" />
                            </div>
                            <div className='text-center mt-1 mb-2'>
                                <h4 className='mainColor uppercase'>Order <br /><b>Processing</b></h4>
                            </div>
                            <div className="text-center text-muted my-2 px-2">
                                <p>The biggest problem for a multi-e-commerce website owner
                                    is time management. One has to make sure that every website
                                    functions properly...</p>            </div>
                            <div className='service-information-pannel'>
                                <div className='text-center mt-1 mb-2'>
                                    <h4 className='mainColor uppercase'>Order <br /><b>Processing</b></h4>
                                </div>
                                <div className="text-center text-light  mt-2 px-3">
                                    <p className='text-justified'>The biggest problem for a multi-e-commerce website owner
                                        is time management. One has to make sure that every website
                                        functions properly and does not fall off the charts. There are
                                        many service providers present in the market claim to solve this
                                        difficulty but very few of them succeed. Here at ManageEcom, we
                                        consider every factor. For example, the introduction of Goods
                                        and Services Tax in India led to a significant amount of hassle
                                        to e-commerce site owners. We cater to those needs as well, and
                                        provide services such as GST filing for online sellers and GST
                                        compliance for online sellers. We also manage payment
                                        reconciliation for online seller. Therefore, you should not
                                        worry much in this regard. You will be able to tackle almost
                                        every difficulty of this field with our help.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4">
                        <div className="card service-card h-fixed card-shadow mt-4 pb-4">
                            <div className="text-center">
                                <img className='pt-0 mt-0 service-img' src={cancelation} alt="management" />
                            </div>
                            <div className='text-center mt-1 mb-2'>
                                <h4 className='mainColor uppercase'>Reconciliation</h4>
                            </div>
                            <div className="text-center text-muted my-2 px-2">
                                <p>We will not disappoint you with very few services. With
                                    ManageEcom, you get to integrate your offline store with your
                                    online one. We also...</p>
                            </div>

                            <div className='service-information-pannel'>
                                <div className='text-center mt-1 mb-2'>
                                    <h4 className='mainColor uppercase'>Reconciliation</h4>
                                </div>
                                <div className="text-center text-light  mt-2 px-3">
                                    <p className='text-justified'>We will not disappoint you with very few services. With
                                        ManageEcom, you get to integrate your offline store with your
                                        online one. We also provide inventory management, catalog
                                        management, and many similar services help you accelerate the
                                        growth of your business. Returns management and order processing
                                        are some other services that we excel in. With this many
                                        services, you can be certain of making the managing process
                                        easier. Such services will help you in overcoming the basic
                                        hassles of owning a number of different e-commerce websites. We
                                        understand all the difficulties of having many e-commerce
                                        websites that is why; we focus on providing all the necessary
                                        services in this field.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4">
                        <div className="card service-card h-fixed card-shadow mt-4 pb-4">
                            <div className="text-center">
                                <img className='pt-0 mt-0 service-img' src={gst} alt="management" />
                            </div>
                            <div className='text-center mt-1 mb-2'>
                                <h4 className='mainColor uppercase'>GST <br /><b>Report Generation</b></h4>
                            </div>
                            <div className="text-center text-muted my-2 px-2">
                                <p>You can contact us today and we will get back to you
                                    immediately. We aim to provide the ...</p>
                            </div>

                            <div className='service-information-pannel'>
                                <div className='text-center mt-1 mb-2'>
                                    <h4 className='mainColor uppercase'>GST <br /><b>Report Generation</b></h4>
                                </div>
                                <div className="text-center text-light  mt-2 px-3">
                                    <p className='text-justified'>You can contact us today and we will get back to you
                                        immediately. We aim to provide the best quality services at the
                                        best prices possible. You can contact us through our email or
                                        our support. In any case, we promise to deliver you the best
                                        results.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4">
                        <div className="card service-card h-fixed card-shadow mt-4 pb-4">
                            <div className="text-center">
                                <img className='pt-0 mt-0 service-img' src={returnO} alt="management" />
                            </div>
                            <div className='text-center mt-1 mb-2'>
                                <h4 className='mainColor uppercase'>Returns <br /><b>Management</b></h4>
                            </div>
                            <div className="text-center text-muted my-2 px-2">
                                <p>Manage courier returns &amp; and customers returns. We aim to provide the... </p>
                            </div>

                            <div className='service-information-pannel'>
                                <div className='text-center mt-1 mb-2'>
                                    <h4 className='mainColor uppercase'>Returns <br /><b>Management</b></h4>
                                </div>
                                <div className="text-center text-light  mt-2 px-3">
                                    <p>Manage courier returns &amp; and customers returns.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
