import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_URL } from "../../api/Custom";
import { getToken } from "../../custome/action";

export const userLoginRegisterApi = createApi({
    reducerPath : 'userLoginRegisterApi',
    baseQuery : fetchBaseQuery({baseUrl:BASE_URL}),
    tagTypes : ['userloginregister'],
    endpoints : (builder) => ({
        getUserData : builder.query({
            query: () => ({
                url: `/manageecom/api/getUserData`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags : ['userloginregister']
        }),
        getSellerDetails: builder.query({
            query: (state) => ({
                url: `/manageecom/api/getUserDetails`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ['userloginregister']
        }),
        loginUser : builder.mutation({
            query :(data)=>({
                url: `/login`,
                method: 'POST',
                body:data,
                headers:  {
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['userloginregister']
        }),
        saveUser : builder.mutation({
            query :(data)=>({
                url: `/saveUser`,
                method: 'POST',
                body:data,
                headers:  {
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['userloginregister']
        }),
        updateUser : builder.mutation({
            query :(data)=>({
                url: `/manageecom/api/updateUserData`,
                method: 'POST',
                body:data,
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['userloginregister']
        }),
        saveContactUs : builder.mutation({
            query:(data)=>({
                url: `/contactUs`,
                method: 'POST',
                body:data,
                headers:  {
                    'Content-Type': 'application/json'
                }
            })
        })
    })
})

export const {useGetUserDataQuery,useGetSellerDetailsQuery,useUpdateUserMutation,useLoginUserMutation,useSaveUserMutation,useSaveContactUsMutation} = userLoginRegisterApi