import { Tooltip } from '@mui/material'
import React from 'react'

export default function TakeTourBtn() {
    return (
        <>
            <Tooltip className='pointer mr-3' title="Start the quick information tour" >
                <small>Take Tour</small>
            </Tooltip>
        </>
    )
}
