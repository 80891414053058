import React, { useState } from 'react';
import { DASHBOARD_BASE_PATH, MANAGE_VARIATION_PATH, UPDATE_TYPE_VARIATION } from '../../../custome/constants';
import { useUpdateRequestData } from '../../../hooks/useUpdateRequestData';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAddUpdateProductVariationsMutation } from '../../../hooks/RTK_Service/productVariationsApi';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import { removeUpdateDetails } from '../../../store/updateData';
import Swal from 'sweetalert2';

const UpdateRemoveVariation = () => {
  const productVariation = useUpdateRequestData(UPDATE_TYPE_VARIATION);
  const [removePids, setRemovePids] = useState("");
  const [variationName, setVariationName] = useState(productVariation?.variationName);
  const warehouse = useSelector((state) => state.warehouse.warehouse)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (productVariation == null || warehouse == null) {
    navigate(DASHBOARD_BASE_PATH + MANAGE_VARIATION_PATH);
  }

  const [jsonData, setJsonData] = useState(JSON.parse(productVariation?.variations?.replace(/'/g, '"')));
  const [updateProductVariationMutation, { isError: pvIsError, error: pvError }] = useAddUpdateProductVariationsMutation();

  if (pvIsError) {
    apiErrorManagemnt2(pvError)
  }

  const updateData = (index, dataIndex, key, value) => {
    setJsonData(prevData => {
      const newData = [...prevData];
      newData[index].data[dataIndex] = { ...newData[index].data[dataIndex], [key]: value };
      return newData;
    });
  };

  const deleteObject = (id, index) => {
    setRemovePids(removePids + id + ",");
    setJsonData(prevData => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
  };

  const handleGetData = () => {
    var obj = {
      action: 'UPDATE',
      productVariationId: productVariation?.productVariationId,
      variations: JSON.stringify(jsonData),
      removeVariationPids: removePids,
      variationName: variationName,
      warehouseId: warehouse?.warehouseId,
    };

    updateProductVariationMutation(obj).then(responce => {
      if (responce?.data?.status == 0) {
        Swal.fire("Success", responce?.data?.message, "success");
        navigate(DASHBOARD_BASE_PATH + MANAGE_VARIATION_PATH);
      } else {
        Swal.fire("Error!!", responce?.data?.message, "error");
      }
    })
  };

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-10 offset-md-1 card p-3'>
          <label>Variation name *</label>
          <input defaultValue={productVariation?.variationName} onChange={(e) => { setVariationName(e.target.value) }} className='form-control' />
          <hr />
          <table className="table table-responsive mt-2">
            <thead>
              <tr>
                <th>PRODUCT</th>
                <th>Key</th>
                <th>Value</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {jsonData.map((item, index) => (
                item.data.map((dataItem, dataIndex) => (
                  <tr key={`${index}-${dataIndex}`}>
                    {dataIndex === 0 && <td rowSpan={item.data.length}><b>PID : </b>{item?.pid}<br/><b>SKU : </b>{item?.psku}</td>}
                    <td>
                      <input
                        type="text"
                        className='form-control'
                        value={dataItem.key}
                        onChange={e => updateData(index, dataIndex, 'key', e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className='form-control'
                        value={dataItem.value}
                        onChange={e => updateData(index, dataIndex, 'value', e.target.value)}
                      />
                    </td>
                    {dataIndex === 0 && (
                      <td rowSpan={item.data.length}>
                        <button className='btn btn-sm btn-danger' onClick={() => deleteObject(item.pid, index)}>Remove</button>
                      </td>
                    )}
                  </tr>
                ))
              ))}
            </tbody>
          </table>
          <hr />
          <button className='btn btn-outline-dark' onClick={handleGetData}>Update Variaiton</button>

        </div>
      </div>
    </div>
  );
};

export default UpdateRemoveVariation;
