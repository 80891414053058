import React from 'react'
import { useState } from 'react';
import { useGetStockHistoryQuery } from '../../../hooks/RTK_Service/stockHistory';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import SearchPannel from '../../../components/custom/SearchPannel';
import { Pagination, Stack } from '@mui/material';
import LinearProressBar from '../../../components/custom/LinearProressBar';

export default function StockHistoryReports() {
    const [pageNumber, setPageNumber] = useState(1);
    const [searchBy, setSearchBy] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const historyReports = useGetStockHistoryQuery({ page: pageNumber, limit: 100, searchBy: searchBy, searchValue: searchValue });
    if (historyReports?.isError) {
        apiErrorManagemnt2(historyReports?.error)
    }
    if (historyReports?.isSuccess) {
        console.log(historyReports?.data);
    }

    const clearSearch = () => {
        setSearchBy("");
        setSearchValue("");
        setPageNumber(1);
        historyReports?.refetch();
    }

    const handleSearch = (type, value) => {
        setSearchValue(value);
        setSearchBy(type);
        historyReports?.refetch();
    }

    const handlePageNumber = (n) => {
        setPageNumber(n);
    }

    return (
        <div>
            <div className="container-fluid ">
                <div className="text-muted mt-1">
                    <h2>STOCK HISTORY</h2>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>

                    </div>
                    <SearchPannel clearSearch={clearSearch} searchedValue={searchValue} handleSearch={handleSearch} keyArray={['sku']} />
                </div>


                <div className="table-responsive">
                    <LinearProressBar value={historyReports?.isFetching || historyReports?.isLoading} />
                    <table className="table table-hover mt-2">
                        <thead style={{ borderBottom: "1px solid black" }}>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">SKU</th>
                                <th scope="col">CHANGE QTY</th>
                                <th scope="col">DESCRIPTION</th>
                                <th scope='col'>CREATED DATE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyReports?.data?.stockHistory?.content?.map((data) => {
                                return (
                                    <tr key={data?.stockHistoryId}>
                                        <td>{data?.stockHistoryId}</td>
                                        <td>{data?.sku}</td>
                                        <td>{data?.changedQuantity}</td>
                                        <td>{data?.description}</td>
                                        <td>{new Date(data?.createdDtTime).toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="right">
                        <Stack spacing={2}>
                            <Pagination color="primary" count={historyReports?.data?.stockHistory?.totalPages} page={pageNumber} onChange={(e, v) => handlePageNumber(v)} />
                        </Stack>
                    </div>

                </div>
            </div>
        </div>
    )
}
