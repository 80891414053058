import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { useAddUpdateProductVariationsMutation, useGetAllProductVariationQuery } from '../../../hooks/RTK_Service/productVariationsApi';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import Swal from 'sweetalert2';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

var options = [
    { label: 'select parent variation product', value: '0' }
];


export default function Product_variations_model({ open, close, product, warehouse }) {
    const [isNewListing, setIsNewListing] = useState(false);
    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');
    const [keyValuePairs, setKeyValuePairs] = useState([]);
    const [key, setKey] = useState('');
    const [jsvalue, setJsValue] = useState('');
    const [variationName, setVariationName] = useState('');
    const [pid, setPid] = useState(1); // Initialize pid with an initial value
    const [error, setError] = useState({ isError: false, message: null });

    const { isLoading, data, isError, error: vpgError, isFetching, isSuccess } = useGetAllProductVariationQuery(warehouse?.warehouseId);
    const [addUpdateProductVariation, { isError: variationIsError, error: variationError }] = useAddUpdateProductVariationsMutation();
    // Create a state variable to manage the error state
    const [myVariationIsError, setMyVariationIsError] = useState(false);

    // Whenever variationIsError changes, update myVariationIsError accordingly
    useEffect(() => {
        onClose();
        setMyVariationIsError(variationIsError);
    }, [variationIsError]);


    if (isError) {
        apiErrorManagemnt2(vpgError);
    }

    if (myVariationIsError) {
        apiErrorManagemnt2(variationError);
        setMyVariationIsError(false);
    }

    if (isSuccess) {
        //alert(JSON.parse(data?.productVariationsList).id)
        options = [];
        data?.productVariationsList?.map(str => {
            const vd = JSON.parse(str);
            var obj = { label: vd.title, value: vd.id }
            options.push(obj);
        })
    }

    const handleNewListing = (e) => {
        setIsNewListing(!isNewListing)
    }

    const onClose = () => {
        setKeyValuePairs([]);
        setValue(options[0]);
        setInputValue('')
        setIsNewListing(false);
        setKey('')
        setJsValue('')
        setError({ isError: false, message: null })
        options = [
            { label: 'select parent variation producr', value: '0' }
        ];
    }
    const handleAdd = () => {
        const newData = { data: [...keyValuePairs, { key, jsvalue }], pid };
        setKeyValuePairs([...keyValuePairs, { key, value: jsvalue }]);
        setKey('');
        setJsValue('');
        setPid(pid + 1); // Increment pid for the next set of data
    };


    const saveVariationDetails = () => {
        if (isNewListing) {
            if (keyValuePairs?.length == 0) {
                // Swal.fire("","enter variation key and its value ","warning");
                setError({ isError: true, message: "enter variation key and its value" });
                return;
            }

            if (variationName?.length == 0) {
                // Swal.fire("","enter variation key and its value ","warning");
                setError({ isError: true, message: "enter unique variaiton name." });
                return;
            }

            var obj = {
                action: 'ADD_NEW',
                productId: product?.productRefId,
                variationName: variationName,
                variations: JSON.stringify([{ data: keyValuePairs, pid: product?.productRefId, psku: product?.sku }], null),
                warehouseId: warehouse?.warehouseId,
            }

            addUpdateProductVariation(obj).then(res => {
                if (res?.data?.status == 0) {
                    Swal.fire("Success", res?.data?.message, "success");
                } else if (res?.data?.status == -1) {
                    Swal.fire("Error!!", res?.data?.message, "error");
                }
            })
        } else {
            if (value == null || value?.value === '0' || value?.label?.length == 0 || value?.value?.length == 0) {
                //Swal.fire("", "select variation product", "info");
                setError({ isError: true, message: "select the parrent variation product!!" });
                return;
            }
            if (keyValuePairs?.length == 0) {
                // Swal.fire("","enter variation key and its value ","warning");
                setError({ isError: true, message: "enter variation key and its value" });
                return;
            }

            data?.productVariationsList?.map(str => {
                const vd = JSON.parse(str);
                if (vd.id == product?.productRefId) {
                    // Swal.fire("Already Exist!!", "product already exist in selected variation", "info");
                    setError({ isError: true, message: "product already exist in selected variation" });
                    return;
                }
            })

            var oldVariations = data?.productVariationsList
                .filter(str => {
                    const vd = JSON.parse(str);
                    return vd.id === value?.value; // Filter out elements that don't match the condition
                })
                .map(str => {
                    const vd = JSON.parse(str);
                    let jsonString = vd.variations;
                    jsonString = jsonString.replace(/'/g, '"');
                    let jsonArray = JSON.parse(jsonString);
                    jsonArray.push({ data: keyValuePairs, pid: product?.productRefId, psku: product?.sku });
                    return jsonArray;
                })
                .filter(item => item !== null); // Filter out null values from the array

            // Flatten the array of arrays into a single array
            var flattenedVariations = [].concat(...oldVariations);

            var obj = {
                action: 'ADD',
                productVariationId: value?.value,
                productId: product?.productRefId,
                variations: JSON.stringify(flattenedVariations, null),
                warehouseId: warehouse?.warehouseId,
            };

            addUpdateProductVariation(obj).then(res => {
                if (res?.data?.status == 0) {
                    Swal.fire("Success", res?.data?.message, "success");
                } else if (res?.data?.status == -1) {
                    Swal.fire("Error!!", res?.data?.message, "error");
                }
            })
        }
        onClose();
        close();
    }

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={close}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => { close(); onClose() }}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Product Variation Listing
                        </Typography>

                    </Toolbar>
                </AppBar>
                <div className='mx-5'>
                    <Typography className="text-center" sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        <>{product?.title}</>
                    </Typography>


                    <div className='row my-4'>
                        <div class="card col-sm-12 col-md-6 offset-md-3 py-3 px-2">
                            {
                                error?.isError &&
                                <div className='alert alert-danger text-center my-2'>{error?.message}</div>
                            }
                            <Typography className="spaceBetween" sx={{ flex: 1 }} variant="h6" component="div">
                                Create new variation product <Checkbox onClick={(e) => handleNewListing(e)} color="primary" />
                            </Typography>
                            {
                                isNewListing === false ?
                                    <>
                                        <label className='mt-2 text-muted'>select product variation </label>
                                        <Autocomplete
                                            className='w-100 mt-3'
                                            value={value}
                                            onChange={(event, newValue) => {
                                                setValue(newValue);
                                            }}
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            id="controllable-states-demo"
                                            options={options}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="product parrent" />}
                                        />
                                    </>
                                    :
                                    <>
                                        <label>Variation name *</label>
                                        <input required onChange={(e) => { setVariationName(e.target.value) }} className='form-control' />
                                    </>
                            }

                            <div className="mt-3">
                                <small className='text-muted'>add variation key and value</small>
                                <div className="input-group mb-3 ">

                                    <input type="text" className="form-control" value={key} onChange={(e) => setKey(e.target.value)} placeholder="Key ex. color" />
                                    <input type="text" className="form-control" value={jsvalue} onChange={(e) => setJsValue(e.target.value)} placeholder="Value ex. red" />
                                    <div className="input-group-prepend">
                                        <button className="btn btn-primary" onClick={handleAdd}>Add</button>
                                    </div>
                                </div>



                                <br />
                                <h6>Key-Value Pairs</h6>

                                <table className='table bg-dark text-light' >
                                    <thead>
                                        <td>KEY</td>
                                        <td>VALUE</td>
                                    </thead>
                                    <tbody>
                                        {keyValuePairs.map((pair, index) => (
                                            <tr key={index}>
                                                <td>{pair.key}</td>
                                                <td>{pair.value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>


                                <br />
                                {/* <pre>{JSON.stringify([{ data: keyValuePairs, pid: product?.productRefId }], null, 2)}</pre> */}
                            </div>
                            <Button color='primary' variant="contained" onClick={() => { saveVariationDetails() }}>
                                save
                            </Button>

                        </div>

                    </div>
                </div>
            </Dialog>
        </div>
    )
}
