import { Alert } from '@mui/material'
import React from 'react'

export default function Information({ info }) {
    return (
        <div>
            {
                (info !== 'na' && info !== 'NA') &&
                <div className='d-none d-md-block'>
                    <Alert  severity="info">{info}</Alert>
                </div>
            }
        </div>
    )
}
