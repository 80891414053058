export const steps = [
    {
        target: 'body',
        title : `👋 Lets start the stock page quick information tour`,
        placement: 'center',
        
    },
    {
        target: '.add-stock-btn',
        title: 'To add new stock details',
        placement:'bottom'
    },
    {
        target: '.importStockDetailsBtn',
        title: 'To add new bulk of stock using excel file',
        placement:'bottom'
    },
    {
        target: '.addComboData',
        title: 'To add combo details',
        placement:'bottom'
    },
    {
        target: '.stockSearch',
        title: 'To search the stocks by sku',
        placement:'bottom'
    },
    {
        target: '.warehouseName',
        title: 'selected warehouse name',
        placement:'bottom'
    },
    {
        target: '.StockTableDetails',
        title: 'Display stock details here',
        placement:'top'
    },
    {
        target: '.updateStaockBtn',
        title: 'To update stock details',
        placement:'bottom'
    }
    

]