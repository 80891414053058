import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SELLERS_BASE_URL } from '../../api/Custom'
import { getToken } from '../../custome/action'

export const productVariationsApi = createApi({
    reducerPath: 'productVariationsApi',
    baseQuery: fetchBaseQuery({ baseUrl: SELLERS_BASE_URL }),
    tagTypes : ['productVariations'],
    endpoints: (builder) => ({
        getAllProductVariation: builder.query({
            query: (warehouseId) => ({
                url: `/getAllProductVariations?warehouseId=${warehouseId}`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags : ['productVariations']
        }),
        getProductVariations : builder.query({
            query:(state) => ({
                url: `/getProductVariations?warehouseId=${state?.warehouseId}&page=${state?.page-1}`,
                method: 'GET',
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags : ['productVariations']
        }),
        addUpdateProductVariations : builder.mutation({
            query : (data)=> ({
                url: `/addUpdateProductVariations`,
                method: 'POST',
                body:data,
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['productVariations']
        })
    })
})

export const { useGetAllProductVariationQuery, useGetProductVariationsQuery, useAddUpdateProductVariationsMutation } = productVariationsApi