import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Button, Divider, Modal, Pagination, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Loader from '../../../components/loader/Loader';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import { setUpdateDetails } from '../../../store/updateData';
import CloseIcon from '@mui/icons-material/Close';
import { UPDATE_TYPE_COMBODATA } from '../../../custome/constants';
import { useCheckSkuPresentOrNotInStockMutation, useGetAllComboDataQuery, useRemoveComboDataMutation, useSaveComboDataMutation } from '../../../hooks/RTK_Service/comboData';
import { useGetAllStockDetailsQuery } from '../../../hooks/RTK_Service/stockApi';
import { ErrorRounded } from '@mui/icons-material';
import SearchPannel from '../../../components/custom/SearchPannel';
const comboDataPanelstyle = {
    position: 'absolute',
    scroll: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const initial = {
    sku: '',
    quantity: 0,
}
export default function ComboData() {
    const [value, setValue] = React.useState();
    const [inputValue, setInputValue] = React.useState('');
    const [showAddComboDataPanel, setShowAddComboDataPanel] = useState(false);
    const [jsonArray, addJsonArray] = useState([]);
    const [error, setError] = useState(false);
    const [comboSku, setComboSku] = useState(null);
    const [comboSkuError, setComboSkuError] = useState(null);
    const [data, setData] = useState(initial);
    const [pageNumber, setPageNumber] = useState(1);

    const[searchBy,setSearchBy]=useState("");
    const[searchValue,setSearchValue]=useState("");
   
    var result = useGetAllComboDataQuery({pageNumber:pageNumber,searchBy:searchBy,searchValue:searchValue});
    const [saveComboData, { isLoading: cdIsLoading, isError: csdIsError, error: cdError }] = useSaveComboDataMutation();
    const [removeComboDataMutation, { isLoading: delateLoading, isError: deleteIsError, error: deleteError }] = useRemoveComboDataMutation();
  

    const options = [];

    const allStockDetails = useGetAllStockDetailsQuery();
    if (allStockDetails.isError) {
        apiErrorManagemnt2(allStockDetails.error)
    }
    if (allStockDetails?.isSuccess) {
        console.log(allStockDetails.data)
        if (allStockDetails?.data?.status === 0) {
            allStockDetails?.data?.stocks?.map(data => {
                console.log(data?.sku)
                options.push(data?.sku);
            });
        }
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    if (deleteIsError) {
        apiErrorManagemnt2(deleteError);
    }
    const handleAddComboDataSubmit = async () => {
        setError(false);
        var flag = true;
        if (inputValue !== null && inputValue.length > 0) {
            jsonArray.map(data => {
                if (JSON.parse(data)?.sku == inputValue) {
                    setError(true);
                    flag = false;
                }
            })
            if (flag) {
                const jsonData = {
                    sku: inputValue?.trim(),
                    quantity: data.quantity
                }
                addJsonArray(current => [...current, JSON.stringify(jsonData)]);
                setData(initial);
            }
        }
    }

    const deleteComboData = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                removeComboDataMutation(id).then(res => {
                    if (res?.data?.status == 0) {
                        Swal.fire("Deleted!", "Combo data record successfully deleted.", "success");
                    }
                })
            }
        });

    }

    const handleJsonComboDataSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (comboSku === null || comboSku == null || comboSku?.length == 0) {
            setComboSkuError("please enter combo sku.");
        } else if (data.get("jsonComboData") !== null && data.get("jsonComboData").length > 0) {
            setComboSkuError(null);
            const dataObj = {
                comboSku: comboSku,
                jsonData: data.get("jsonComboData")
            }
            saveComboData(dataObj).then((res) => {
                if (res?.data?.status === 0) {
                    addJsonArray([]);
                    setShowAddComboDataPanel(false);
                    Swal.fire("Data Save!!", res?.data?.message, "success");
                }
                if (res?.data?.status === 1) {
                    addJsonArray([]);
                    setShowAddComboDataPanel(false);
                    Swal.fire("Error!!", res?.data?.message, "error");
                }
            })
        }
    }

    const handleUpdateComboData = (data) => {
        dispatch(setUpdateDetails({ type: UPDATE_TYPE_COMBODATA, data: data }))
        navigate("/dashboard/update_combo_data");
    }


    if (result?.isLoading || result?.isFetching) {
        return <Loader action={true} />
    }

    if (result?.isError) {
        apiErrorManagemnt2(result?.error);
    }

    const clearSearch = ()=>{
        setSearchBy("");
        setSearchValue("");
        setPageNumber(1);
        result.refetch();
    }

    const handleSearch = (type, value) => {
        setSearchValue(value);
        setSearchBy(type);
        result.refetch();
    }

    return (
        <div className="container-fluid text-muted">
            <div >
                <div className='mt-2 spaceBetween'>
                    <div className="text-muted alignCenter" >
                        <h3>COMBO DATA</h3>
                    </div>
                </div>
            </div>


            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className='left my-2'>
                    <Button onClick={() => setShowAddComboDataPanel(!showAddComboDataPanel)} variant="contained">  + add combo data</Button>
                </div>
                <SearchPannel clearSearch={clearSearch} searchedValue={searchValue} handleSearch={handleSearch} keyArray={['Combo sku']} />
            </div>

            <div className="table-responsive">
                <table className="table table-hover mt-2">
                    <thead style={{ borderBottom: "1px solid black" }}>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">COMBO SKU</th>
                            <th scope="col">JSON DATA</th>
                            <th scope="col">CREATED DT TIME</th>
                            <th scope="col">ACTIONS</th>
                            <th scope='col'>DELETE</th>
                        </tr>
                    </thead>
                    <tbody>

                        {result?.data?.content?.map((data) => {
                            return (
                                <tr className='' key={"ComboID" + data.comboDataId}>
                                    <td className='text-center'>{data.comboDataId}</td>
                                    <td>{data.comboSku}</td>
                                    <td className='' style={{ width: "45%" }}>{data.jsonData}</td>
                                    <td className='text-center'>{data.createdDtTime}</td>
                                    <td className='text-center'>
                                        <Stack direction="row" >

                                            <IconButton onClick={() => { handleUpdateComboData(data) }} aria-label="update">
                                                <EditIcon />
                                            </IconButton>

                                            <Link to="/dashboard/remove_combo_data" className=''>
                                                <IconButton aria-label="remove">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Link>
                                        </Stack>
                                    </td>
                                    <td><button onClick={() => { deleteComboData(data.comboDataId) }} className='btn btn-sm btn-danger'>Delete</button></td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="right">
                    <Stack spacing={2}>
                        <Pagination color="primary" count={result?.data?.totalPages} page={pageNumber} onChange={(e, v) => setPageNumber(v)} />
                    </Stack>
                </div>

            </div>



            {/* modal for add combo data */}
            <Modal
                open={showAddComboDataPanel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={comboDataPanelstyle}>
                    <div onClick={() => { addJsonArray([]); setShowAddComboDataPanel(!showAddComboDataPanel) }}
                        className="right"><Tooltip className='pointer' title="close"><CloseIcon /></Tooltip></div>
                    <Typography className="text-primary" id="modal-modal-title" variant="h6" component="h2">
                        <i className="fa-solid fa-boxes-stacked px-2 fa-2x"></i>
                        ADD COMBO DATA
                    </Typography>
                    {
                        csdIsError &&
                        <span className='text-danger'>{cdError}</span>
                    }
                    <Box noValidate sx={{ mt: 1, mb: 2 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="comboSku"
                            label="Combo SKU"
                            onChange={(e) => { setComboSku(e.target.value) }}
                            multiline
                            autoFocus
                            name="comboSku"
                        />
                        {comboSkuError && <span className='text-danger'>{comboSkuError}</span>}
                        <Autocomplete
                            // value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            id="controllable-states-demo"
                            options={options}
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="Select SKU" />}
                        />
                        {error && <span className='text-danger'>This sku is already present in json data !!</span>}
                        <TextField
                            margin="normal"
                            fullWidth
                            id="comboquantity"
                            onChange={(e) => setData({ ...data, quantity: e.target.value })}
                            multiline
                            value={data.quantity}
                            label="Quantity"
                            name="comboquantity"
                        />

                        <Button
                            onClick={() => { handleAddComboDataSubmit() }}
                            variant="contained"
                            fullWidth
                            sx={{ mt: 2, mb: 1, py: 1 }}
                        >
                            Create Json data
                        </Button>
                    </Box>
                    <Divider>JSON DATA</Divider>
                    <Box component="form" onSubmit={(e) => { handleJsonComboDataSubmit(e) }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="jsonComboData"
                            placeholder='Example : {"key1":"value1","key2":"value2"}'
                            defaultValue={jsonArray.length > 0 ? "[" + jsonArray + "]" : ''}
                            multiline
                            rows={4}
                            mt={0}
                            name="jsonComboData"
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{ mt: 2, mb: 1, py: 1 }}
                        >
                            add combo data
                        </Button>
                    </Box>

                </Box>
            </Modal>
            {/* searching modal end */}
        </div>
    )
}
