export const steps = [
    {
        target: 'body',
        title : `👋 Lets start the manage product information tour`,
        placement: 'center',
        
    },
    {
        target: '.addProductBtn',
        title: 'Click this button to add new product in your warehouse',
        placement:'bottom'
    },
    {
        target: '.importProductDataBtn',
        title: 'Click this button to add bulk of products using excel file',
        placement:'bottom'
    },
    {
        target: '.searchPannel',
        title: 'To search the product from selected warehouse',
        placement:'left'
    },
    {
        target: '.warehouseName',
        title: 'Selected warehouse name showing here',
        placement:'bottom'
    },
    {
        target: '.changeWarehouseBtn',
        title: 'If want to change warehouse then click here!!',
        placement:'left'
    },
    {
        target: '.productTable',
        title: 'All product details show here',
        placement:'top'
    },
    {
        target: '.updateBtn',
        title: 'Update the product details',
        placement:'bottom'
    },
    {
        target: '.deleteBtn',
        title: 'Delete the product details',
        placement:'bottom'
    },
]