import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SELLERS_BASE_URL } from '../../api/Custom'
import { getToken } from '../../custome/action'

export const shippingPartnerApi = createApi({
    reducerPath: 'shippingPartnerApi',
    baseQuery: fetchBaseQuery({ baseUrl: SELLERS_BASE_URL }),
    tagTypes : ['shippingPartner'],
    endpoints: (builder) => ({
        getShippingPartnerData: builder.query({
            query:(data)=>({
                url: `/getShippingPartner?page=${data?.page-1}&limit=${data?.limit}&type=${data?.type}`,
                method:"GET",
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags:['shippingPartner']
        }),
        saveShippingPartnerData : builder.mutation({
            query : (data)=> ({
                url: `/saveShippingPartner`,
                method: 'POST',
                body:data,
                headers:  {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            invalidatesTags:['shippingPartner']
        }),
    })
})

export const {useGetShippingPartnerDataQuery ,useSaveShippingPartnerDataMutation } = shippingPartnerApi