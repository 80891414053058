import { Box, LinearProgress } from '@mui/material'
import React from 'react'

export default function LinearProressBar({ value }) {
    return (
        <div>
            {
                value &&
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            }

        </div>
    )
}
