import * as React from 'react';
import { useState } from 'react';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import { useGenerateLabelsMutation, useGetChannelOrdersQuery } from '../../../hooks/RTK_Service/channelOrders';
import { Button, Checkbox, MenuItem, Pagination, Select, Stack } from '@mui/material';
import amazonLogo from '../../../files/imgs/amzon_logo2.png'
import flipkartLogo from '../../../files/imgs/flipkart_logo2.png'
import LinearProressBar from '../../../components/custom/LinearProressBar';
import Swal from 'sweetalert2';
import BtnLodderIcon from '../../../components/custom/BtnLodderIcon';

const columns = [
  { field: 'id', headerName: 'ID', width: 180 },
  { field: 'channel', headerName: 'CHANNEL' },
  { field: 'sku', headerName: 'SKU', width: 200 },
  // { field: 'shelf', headerName: 'SHELF', width: 90 },
  { field: 'channelSku', headerName: 'CHANNEL SKU', width: 200 },
  { field: 'qty', headerName: 'QTY', width: 'auto' },
  { field: 'warehouseId', headerName: 'WAREHOUSE ID' },
  { field: 'soldPrice', headerName: 'SOLD PRICE' },
  { field: 'shipDate', headerName: 'CREATED DATE' },
];

export default function OrdersTable({ status, channel }) {
  const [selectedIds, setSelectedIds] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectAll, setSelectAll] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [printLabel, setPrintLabel] = useState(false);


  const [generateLabelMutation, generateLabelresponse] = useGenerateLabelsMutation();

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, id]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((rowId) => rowId !== id)
      );
    }
    setPrintLabel(true);
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allIds = rows.map((data) => data.id + "~" + data?.channelId + "~" + data?.channel);
      setSelectedRows(allIds);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
    setPrintLabel(event.target.checked);
  };


  const handlePrintLabel = () => {

    if(selectedRows.length===0){
      Swal.fire("Orders not selected for creating labels!!","","warning");
      return;
    }
    //alert(selectedRows.join(", "));
    let map = new Map();
    let flipkartMap = new Map();
    let amazonMap = new Map();

    let orderIds = selectedRows.join(", ");
    orderIds.split(",").forEach(odId => {
      let array = odId.split("~");
      let orderId = array[0];
      let channelId = parseInt(array[1]);
      let channel = array[2];

      if (!map.has(channelId)) {
        map.set(channelId, channel);
      }

      if (map.get(channelId) === "AMAZON") {
        if (amazonMap.has(channelId)) {
          amazonMap.set(channelId, amazonMap.get(channelId) + orderId + ",");
        } else {
          amazonMap.set(channelId, orderId + ",");
        }
      } else if (map.get(channelId) === "FLIPKART") {
        if (flipkartMap.has(channelId)) {
          flipkartMap.set(channelId, flipkartMap.get(channelId) + orderId + ",");
        } else {
          flipkartMap.set(channelId, orderId + ",");
        }
      }
    });

    try {
      generateLabelMutation({
        amazonMap: Object.fromEntries(amazonMap),
        flipkartMap: Object.fromEntries(flipkartMap)
      }).then(res => {
        if(res?.data?.status===0){
          Swal.fire("SUCCESS","label is generate","success");
        }
        if(res?.error?.data?.status===-1){
          Swal.fire("ERROR!!",res?.error?.data?.message,"error")
        }
      },
      error=>{
        Swal.fire("ERROR!!",error?.data?.message,"error")
      }

      )
    } catch (error) {
      console.error('API error:', error);
    }

  };

  var rows = [];
  const obj = {
    page: pageNumber,
    limit: 40,
    channel: channel,
    status: status,
  }
  const channelOrders = useGetChannelOrdersQuery(obj);

  if (channelOrders?.isError) {
    apiErrorManagemnt2(channelOrders?.error);
  }


  if (channelOrders.isSuccess) {
    channelOrders?.data?.salesOrder?.content?.map((data) => {
      let obj = {
        id: data[0]?.channelOrderId,
        channel: data[2]?.channel,
        sku: data[1]?.sku,
        channelSku: data[1]?.channelSku,
        qty: data[1]?.quantity,
        warehouseId: data[0]?.warehouseId,
        soldPrice: data[0]?.soldPrice,
        createdDtTime: data[0]?.createdDtTime,
        channelId: data[2]?.channelId
      }
      rows.push(obj);
    })
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <div className="table-responsive">
          <LinearProressBar value={channelOrders?.isLoading} />
          <div>
            {(printLabel) && (
              <Button disabled={generateLabelresponse?.isLoading } variant="contained" onClick={handlePrintLabel}>
                Create Label <BtnLodderIcon action={generateLabelresponse?.isLoading }/>
              </Button>
            )}
            <table className="table table-hover">
              <thead style={{ borderBottom: "1px solid black" }}>
                <tr>
                  <th width="5px">
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </th>
                  <th scope="col">ID</th>
                  <th scope="col">SKU</th>
                  <th scope="col">CHANNEL_SKU</th>
                  <th scope="col">QTY</th>
                  <th scope="col">W.ID</th>
                  <th scope="col">PRICE</th>
                  <th scope="col">CREATED_DATE</th>
                </tr>
              </thead>
              <tbody>
                {rows?.map((data) => {
                  return (
                    <tr
                      key={data.id}
                      style={{ cursor: "pointer" }}
                      className="table-row-hover"
                    >
                      <td>
                        <Checkbox
                          checked={selectedRows.includes(data.id + "~" + data?.channelId + "~" + data?.channel)}
                          onChange={(e) => handleCheckboxChange(e, data.id + "~" + data?.channelId + "~" + data?.channel)}
                        />
                      </td>
                      <td className="px-1 m-0">
                        <span className='d-flex'>
                          {data.channel === "FLIPKART" && (
                            <img src={flipkartLogo} height={22} width={22} />
                          )}
                          {data.channel === "AMAZON" && (
                            <img src={amazonLogo} height={22} width={22} />
                          )}
                          &nbsp;{data.id}
                        </span>
                      </td>
                      <td className="px-1 m-0">{data.sku}</td>
                      <td className="px-1 m-0">{data.channelSku}</td>
                      <td className="px-1 m-0">{data.qty}</td>
                      <td className="px-1 m-0">{data.warehouseId}</td>
                      <td className="px-1 m-0">{data.soldPrice}</td>
                      <td className="px-1 m-0">
                        {new Date(data.createdDtTime).toLocaleString()}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>


          <div className="right">
            <Stack spacing={2}>
              {
                <Pagination color="primary" count={channelOrders?.data?.salesOrder?.totalPages} page={pageNumber} onChange={(e, v) => setPageNumber(v)} />
              }
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}
