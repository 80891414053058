import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'

export default function ShippingPartnerCards({image,title,desc,setShippingPartner,spName,setOpenTable,openTableValue}) {
    return (
        <div>
            <Card sx={{ maxWidth: 400 }}>
                <CardMedia
                    sx={{ height: 140 }}
                    image={image}
                    title="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                       {desc}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={() => { setShippingPartner(spName) }} size="small">Link Warehouse</Button>
                    <Button onClick={() => { setOpenTable(openTableValue) }} size="small">View</Button>
                </CardActions>
            </Card>
        </div>
    )
}
