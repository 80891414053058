import React, { useState } from 'react'
import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import UnmappedTable from './UnmappedTable';
import MapTable from './MapTable';
import Loader from '../../../components/loader/Loader';
import { apiErrorManagemnt } from '../../../api/Custom';
import { useGetChannelProductDataMutation } from '../../../hooks/RTK_Service/MapUnmapProductData';
import SearchPannel from '../../../components/custom/SearchPannel';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { dawnloadExcelFile } from '../../../api/JobManagement';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import BtnLodderIcon from '../../../components/custom/BtnLodderIcon';
import { useNavigate } from 'react-router-dom';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const array = ['map', 'unmap'];
export default function MapUnmapProducts() {
    const [unvalue, setUnValue] = React.useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [tabName, setTabName] = useState('map');
    const [loading, setLoading] = useState(false);

    const [searchBy, setSearchBy] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate();

    var unmappedData = null;
    var mappedData = null;
    const [getChannelProductMutation, result] = useGetChannelProductDataMutation();

    const handleUnpaddesTabs = (event, newValue) => {
        setPageNumber(1);
        getChannelProductMutation({ pageNumber: 1, type: array[newValue], searchBy: searchBy, searchValue: searchValue })
        setUnValue(newValue);
    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    useState(() => {
        getChannelProductMutation({ pageNumber, type: tabName, searchBy: '', searchValue: '' }).then(res => {
        })
    }, [])

    if (result?.error) {
        apiErrorManagemnt(result?.error);
    }

    if (result?.isLoading) {
        return <Loader action={true} />
    }


    if (result?.data) {
        if (tabName == 'map') {

            mappedData = result.data;
        } else if (tabName == 'unmap') {
            unmappedData = result.data;
        }

    }

    const clearSearch = () => {
        setSearchBy("");
        setSearchValue("");
        setPageNumber(1);
        getChannelProductMutation({ pageNumber: 1, type: tabName, searchBy: "", searchValue: "" })
    }

    const handleSearch = (type, value) => {
        setSearchValue(value);
        setSearchBy(type);
        getChannelProductMutation({ pageNumber: 1, type: tabName, searchBy: type, searchValue: value })
    }

    const handlePageNumber = (pageNumber) => {
        setPageNumber(pageNumber);
        getChannelProductMutation({ pageNumber: pageNumber, type: tabName, searchBy: searchBy, searchValue: searchValue })
    }

    const handleDownloadExcelFile = async () => {
        setLoading(true);
        await dawnloadExcelFile("-1", tabName === 'map' ? "MAPPED_PRODUCT" : "UNMAPPED_PRODUCT");
        setLoading(false);
    }


    return (
        <>
            <div className="container-fluid ">
                <div className="text-muted mt-1">
                    <h2>CHANNEL PRODUCTS</h2>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <Button onClick={() => { navigate("/dashboard/job_batch_management") }} variant="contained" className="mx-1 importStockDetailsBtn" ><UploadFileIcon />&nbsp; Import {tabName === 'map' ? "mapped data" : "unmapped data"}</Button>
                        <Button disabled={loading} onClick={() => { handleDownloadExcelFile(); }} variant="contained" className="mx-1" >{loading ? <BtnLodderIcon action={loading} /> : <CloudDownloadIcon />}&nbsp; Export {tabName === 'map' ? "mapped data" : "unmapped data"}</Button>
                    </div>
                    <SearchPannel clearSearch={clearSearch} searchedValue={searchValue} handleSearch={handleSearch} keyArray={['Channel sku', 'Sku']} />
                </div>

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={unvalue} onChange={handleUnpaddesTabs} aria-label="basic tabs example">
                            <Tab onClick={() => { setTabName('map') }} label="Mapped Products" {...a11yProps(0)} />
                            <Tab onClick={() => { setTabName('unmap') }} label="Unmapped Products" {...a11yProps(1)} />
                            <Tab label="Deleted Products" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={unvalue} index={0}>
                        {
                            <MapTable MapProductData={mappedData} pageNumber={pageNumber} handlePageNumber={handlePageNumber} />
                        }
                    </CustomTabPanel>
                    <CustomTabPanel value={unvalue} index={1}>
                        <UnmappedTable UnmapProductData={unmappedData} pageNumber={pageNumber} handlePageNumber={handlePageNumber} />
                    </CustomTabPanel>
                    <CustomTabPanel value={unvalue} index={2}>
                        Item Three
                    </CustomTabPanel>
                </Box>
            </div>
        </>
    )
}
