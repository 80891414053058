import { Button, Pagination, Stack, TextField } from '@mui/material'
import React from 'react'

export default function MapTable({ MapProductData, pageNumber, handlePageNumber }) {

  return (
    <div>
    
      <div className="table-responsive">
        <table className="table table-hover mt-2">
          <thead style={{ borderBottom: "1px solid black" }}>
            <tr>
              <th scope="col">CHANNEL ID</th>
              <th scope="col">CHANNEL SKU</th>
              <th scope="col">CHANNEL PRODUCT ID</th>
              <th scope="col">CHANNEL PRODUCT TITLE</th>
              <th scope='col'>SKU</th>
              <th scope="col">CHANNEL PRICE</th>
              <th scope="col">CHANNEL SALE PRICE</th>
              <th scolpe="col">LINK</th>
            </tr>
          </thead>
          <tbody>
            {MapProductData?.content?.map((data) => {
              return (
                <tr key={"map" + data.Id}>
                  <td>{data?.channelId}</td>
                  <td>{data.channelSku}</td>
                  <td>{data.channelProductId}</td>
                  <td>{data.channelProductTitle}</td>
                  <td>{data.sku}</td>
                  <td>{data.channelPrice}</td>
                  <td>{data.channelSalePrice}</td>
                  <td><button className='btn btn-sm btn-outline-warning'>Update</button></td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="right">
          <Stack spacing={2}>
            <Pagination color="primary" count={MapProductData?.totalPages} page={pageNumber} onChange={(e, v) => handlePageNumber(v)} />
          </Stack>
        </div>

      </div>
    </div>
  )
}
