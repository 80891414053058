import React, { useState } from 'react'
import { useGetUserDataQuery, useUpdateUserMutation } from '../../hooks/RTK_Service/userLoginRegister'
import { apiErrorManagemnt2 } from '../../api/Custom';
import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { showLoading } from '../../store/loader';
import BtnLodderIcon from '../../components/custom/BtnLodderIcon';
import { removeUserDetails } from '../../store/userSlice';

export default function Profile() {
    const userQuery = useGetUserDataQuery();

    const [updateMutation, { isError: updateIsError, error: updateError, isLoading: updateLoading }] = useUpdateUserMutation();

    const dispatch = useDispatch();

  
    if (userQuery?.isError) {
        apiErrorManagemnt2(userQuery?.error);
    }

    if (updateIsError) {
        apiErrorManagemnt2(updateError);
    }


    const handleUpdate = async (event) => {
        event.preventDefault();


        const data = new FormData(event.currentTarget);
        var obj = {
            firstName: data.get("firstName"),
            lastName: data.get("lastName"),
            companyName: data.get("companyName"),
            email: userQuery?.data?.user?.email,
            gstIn: data.get("gstin"),
            phone: data.get("phone"),
            pincode: data.get("pincode"),
            address: data.get("address"),
        }

        updateMutation(obj).then(res => {
            if (res?.data?.status == 0) {
                window.localStorage.setItem("name", data.get("firstName"));
                Swal.fire("Updated!", "profile data is successfully updated.", "success")
                dispatch(removeUserDetails());
            }
        })
    }

    return (
        <div className='container-fluid paper'>
            <div className='row my-4'>
                <div className='col-12 col-md-6 offset-md-3'>
                    <Card sx={{ width: "100%" }}>
                        <Typography className='text-center mt-1 mb-3' fontSize={20} variant='h1' >UPDATE PROFILE</Typography>
                        <Box className='center' component="form" onSubmit={handleUpdate} sx={{ mt: 3 }}>
                            <Grid container xs={12} sm={12} md={11} >
                                <Grid item xs={12} md={12} >
                                    <TextField
                                        autoComplete="given-name"
                                        name="email"
                                        required
                                        fullWidth
                                        id="email"
                                        disabled
                                        defaultValue={userQuery?.data?.user?.email}
                                        label="Email"
                                        multiline
                                        sx={{ mb: 2 }}
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} mb={2} pr={1}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="firstName"
                                        required
                                        fullWidth
                                        multiline
                                        defaultValue={userQuery?.data?.user?.firstName}
                                        id="firstName"
                                        label="First Name"
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} mb={2} >
                                    <TextField
                                        autoComplete="given-name"
                                        name="lastName"
                                        required
                                        fullWidth
                                        multiline
                                        defaultValue={userQuery?.data?.user?.lastName}
                                        id="lastName"
                                        label="Last Name"
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} mb={2} pr={1}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="phone"
                                        required
                                        fullWidth
                                        multiline
                                        defaultValue={userQuery?.data?.user?.phone}
                                        id="phone"
                                        label="Contact number"
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} mb={2} >
                                    <TextField
                                        autoComplete="given-name"
                                        name="pincode"
                                        required
                                        fullWidth
                                        multiline
                                        defaultValue={userQuery?.data?.user?.pincode}
                                        id="pincode"
                                        label="Pincode"
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </Grid>

                                <Grid item xs={12} >
                                    <TextField
                                        autoComplete="given-name"
                                        name="companyName"
                                        required
                                        fullWidth
                                        id="companyName"
                                        defaultValue={userQuery?.data?.user?.companyName}
                                        label="Company name"
                                        multiline
                                        sx={{ mb: 2 }}
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </Grid>

                                <Grid item xs={12} >
                                    <TextField
                                        autoComplete="given-name"
                                        name="gstin"
                                        required
                                        fullWidth
                                        id="gstin"
                                        defaultValue={userQuery?.data?.user?.gstIn}
                                        label="GST number"
                                        multiline
                                        sx={{ mb: 2 }}
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </Grid>

                                <Grid item xs={12} >
                                    <TextField
                                        autoComplete="given-name"
                                        name="address"
                                        required
                                        fullWidth
                                        id="address"
                                        label="address"
                                        defaultValue={userQuery?.data?.user?.address}
                                        multiline={4}
                                        sx={{ mb: 2 }}
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </Grid>


                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, py: 2 }}
                                >
                                    UPDATE PROFILE <BtnLodderIcon action={updateLoading} />
                                </Button>

                            </Grid>
                        </Box>
                    </Card>
                </div>
            </div>
        </div>


    )
}
