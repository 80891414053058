import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
    name:'loader',
    initialState:{
        loading:false,
        error:null,
    },
    reducers:{
        showLoading : (state) =>{
            return  state = {
                loading:true,
                error:null,
            }
        },
        hideLoading : (state)=>{
            return state = {
                loading:false,
                error:null,
            }
        }
    }
})

export const {showLoading,hideLoading} = loaderSlice.actions;
export default loaderSlice.reducer;