import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import CheckIcon from '@mui/icons-material/Check';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { Helmet } from 'react-helmet';
const tiers = [
    {
        title: 'Basic',
        subheader: 'popular',
        price: '500',
        description: [
            'Inventory Management(500 Units)',
            'Order Processing - FREE',
            'Payment Reconciliation - FREE',
            'Returns Reconciliation - FREE',
            'Support Provided'
        ],
        buttonText: 'Register',
        buttonVariant: 'outlined',
    },
    {
        title: 'GST',
        subheader: 'Most popular',
        price: '500',
        description: [
            'Supports Amazon,Flipkart,eBay,Offline Sales',
            'No Manual work needed',
            'GSTR1B & GSTR3B report generation',
            'Support Provided',
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
    },
    {
        title: 'Standerd',
        subheader: 'popular',

        price: '5000',
        description: [
            'Inventory Management(5000 Units)',
            'Order Processing - FREE',
            'Payment Reconciliation - FREE',
            'Returns Reconciliation - FREE',
            'Support Provided',
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
    },
];

export default function Pricing() {
    return (
        <div>
            <Helmet>
                <title>Pricing - Manageecom Solutions</title>
                <meta name="description" content="Manageecom software gives you all multichannel eCommerce business management features at the best price. " />
                {/* multiple link elements */}
                <link rel="canonical" href={process.env.REACT_APP_MANAGEECOM_BASE_URL + "/pricing"} />
            </Helmet>
            
            <Header />
            <div className='back-small-div' />

            <Container className="back-small-div-container mb-4">
                <div className='center'>
                    <div >
                        <h1 className="text-light text-center"><b>PRICING</b></h1>
                        <h6 className='text-light text-center'>Simple pricing at just ₹ 1 per order.</h6>
                    </div>
                </div>
            </Container>


            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />

            {/* End hero unit */}
            <Container className='mt-15' maxWidth="lg" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {tiers.map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid
                            item
                            key={tier.title}
                            xs={12}
                            sm={tier.title === 'Standerd' ? 12 : 6}
                            md={4}
                        >
                            <Card>
                                <CardHeader
                                    title={tier.title}
                                    subheader={tier.subheader}
                                    titleTypographyProps={{ align: 'center' }}
                                    action={tier.title === 'GST' ? <StarIcon /> : null}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent sx={{ height: tier.title === 'GST' ? '420px' : '400px' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 5,
                                        }}
                                    >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            ₹{tier.price}
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary">
                                            (18% GST Extra)
                                        </Typography>
                                    </Box>
                                    <ul>
                                        {tier.description.map((line) => (
                                            <Typography
                                                component="li"
                                                variant="subtitle1"
                                                align="center"
                                                key={line}
                                            >
                                                <div className='my-1'><CheckIcon sx={{ color: "green" }} /> {line}<hr /></div>
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button className='py-2' fullWidth variant={tier.buttonVariant}>
                                        {tier.buttonText}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 8,
                    py: [3, 6],
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">

                </Grid>
            </Container>
            <Footer/>
        </div>
    )
}
