import React, { useState } from 'react'
import Joyride from 'react-joyride'

export default function GuideTour({ action, guideSteps }) {
  const [steps, setSteps] = useState(guideSteps)
  return (
    <div>
      <Joyride
        disableOverlayClose={true}
        continuous
        callback={() => { }}
        run={action}
        steps={steps}
        hideCloseButton
        scrollToFirstStep
        showSkipButton
        showProgress
        styles={{
          options: {
            // modal arrow and background color
           // arrowColor: "#eee",
           // backgroundColor: "#eee",
            // page overlay color
            overlayColor: "rgba(0, 0, 0, 0.7)",
            //button color
           // primaryColor: "mediumaquamarine",
            //text color
           // textColor: "#333",
            //width of modal
            width: 500,
            //zindex of modal
            zIndex: 1000
          }
        }}
      />

      {/* <ReactJoyride
        steps={guideSteps}
        run={action}
        continuous
        showProgress
        showSkipButton
        styles={{
          options: {
            // modal arrow and background color
            arrowColor: "#eee",
            backgroundColor: "#eee",
            // page overlay color
            overlayColor: "rgba(79, 26, 0, 0.4)",
            //button color
            primaryColor: "mediumaquamarine",
            //text color
            textColor: "#333",

            //width of modal
            width: 500,
            //zindex of modal
            zIndex: 1000
          }
        }}
      /> */}

    </div>
  )
}
