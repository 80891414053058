import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SELLERS_BASE_URL } from '../../api/Custom'
import { getToken } from '../../custome/action'

export const jobBatchApi = createApi({
    reducerPath: 'jobBatchApi',
    baseQuery: fetchBaseQuery({ baseUrl: SELLERS_BASE_URL }),
    tagTypes : ['jobBatch'],
    endpoints: (builder) => ({
        getJobTypeData: builder.query({
            query: (data) => ({
                url: `/getJobTypeDetails?page=${data?.page-1}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
            providesTags:['jobBatch']
        }),

        uploadExcelFile: builder.mutation({
            query: (data) => ({
                url: `/uploadExcelFile`,
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                }
            }),
            invalidatesTags:['jobBatch']
        }),
    })
})

export const { useUploadExcelFileMutation, useGetJobTypeDataQuery } = jobBatchApi