import React from 'react'
import { Link } from 'react-router-dom'
import error from '../../src/files/imgs/404.gif'

export default function NoPage() {
  return (
    <div className='center'>
        <img src={error} style={{width:"535",height:"462px"}} alt="404 page not found!!" />
    </div>
  )
}
