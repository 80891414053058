import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { saveChannelSettings } from '../../../api/ChannelSetting';
import axios from 'axios';
import { getToken } from '../../../custome/action';
import { BASE_URL } from '../../../api/Custom';
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


export default function SnapdealSetting({ infoData, snapdeal, handleSnapdeal }) {
    const [channelSetting, setChannelSettings] = useState(null);

    const handleSnapdealSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataResult = {
            X_SELLER_AUTH_ZTOKEN: data.get("X_SELLER_AUTH_ZTOKEN"),
            CLIENT_ID: data.get("CLIENT_ID"),
            userId: infoData.userId,
            channelId: infoData.channelId,
            channel: infoData.channel
        }

        await saveChannelSettings(dataResult);
        handleSnapdeal();
    }

    const getChannelSetting = async () => {
        await axios.post(`${BASE_URL}/manageecom/api/getChannelSetting`, infoData, {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                'Content-Type': 'application/json'
            }
        }).then(
            (response) => {
                setChannelSettings(response.data);
                return response.data;
            },
            (error) => {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops, Something went wrong!',
                    text: error.message,
                })
            }
        ).catch(err => {
            return (err)
        });
    }

    useEffect(() => {
        const init = async () => {
            setChannelSettings(null);
            if (snapdeal) {
                getChannelSetting()
            }
        }
        init();
    }, [snapdeal])

    return (
        <div>

            <Modal
                open={snapdeal}
                onClose={handleSnapdeal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <b>SNAPDEAL SETTINGS</b>
                    </Typography>
                    <Box component="form" onSubmit={handleSnapdealSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="X_SELLER_AUTH_ZTOKEN"
                            label="X_SELLER_AUTH_ZTOKEN"
                            name="X_SELLER_AUTH_ZTOKEN"
                            autoComplete="X_SELLER_AUTH_ZTOKEN"
                            defaultValue={channelSetting?.xsellerAuthZToken}
                            multiline
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="CLIENT_ID"
                            label="CLIENT_ID"
                            id="CLIENT_ID"
                            defaultValue={channelSetting?.clientId}
                            multiline
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, py: 2, }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}
