import axios from "axios";
import { BASE_URL, apiErrorManagemnt } from "./Custom";
import { getToken } from "../custome/action";
import Swal from "sweetalert2";
export const saveChannelSettings=async(data) => {
    await axios.post(`${BASE_URL}/manageecom/api/updateChannelSettings`,data,{
        headers: {
            'Authorization':'Bearer '+getToken(),
            'Content-Type': 'application/json'
        }
    }).then(
        (response) => {
            Swal.fire(
                'Success',
                'Channel setting is updated successfully.',
                'success'
              )
            return response.data;
        },
        (error) => {
            apiErrorManagemnt(error);
            return error;
        }
    ).catch(err => {
        return (err)
    });
}