import Swal from "sweetalert2";


export const saveDetails = (token, name) => {
    localStorage.setItem("token", token);
    localStorage.setItem("name", name)
}


export const removeDetails = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("name");
    localStorage.removeItem("login_from");
}
export const getToken = () => {
    let token = window.localStorage.getItem("token");
    if (token !== null) {
        return token;
    } else
        return null;
}
export const getName = () => {
    return localStorage.getItem("name")
}
export const logout = () => {
    if (localStorage.getItem("login_from") === "nuviosellers" || localStorage.getItem("login_from") == "nuviosellers") {
        window.open("https://nuviosellers.com/adminLogin?logout");
    } else {
        window.location.assign("/login");
        Swal.fire({
            icon: 'success',
            toast: true,
            position: 'bottom-right',
            text: 'Successfully logout .',
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
        })
    }
    removeDetails();
}

//nuvioseller
export const saveLoginFromOutside = () => {
    localStorage.setItem("login_from", "nuviosellers");
}