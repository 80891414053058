import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import about from '../../files/imgs/about.svg'
import { Link } from 'react-router-dom';
export default function About() {
    return (
        <div  className='about-container title-section2'>
            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="col-12 col-sm-6">

                    <h1 className='uppercse mainColor2'>About us</h1>
                    <p className="text-muted">
                        ManageEcom is a well known name in the eCommerce industry in India. The company was founded by a group of budding professionals. ManageEcom is an online eCommerce solutions organization that deliver real results to businesses globally with assured level of reliability and performance.</p>
                    <div className='left my-3'>
                        <a className='text-success' href="https://api.whatsapp.com/send/?phone=917718895734" ><WhatsAppIcon/></a>&nbsp;
                    </div>
                    <Link to="/about" className='px-5 py-2 mt-3 text-light' style={{ backgroundColor: 'var(--mainColor2)', border: 'none', borderRadius: '20px',textDecoration:"none" }}>Read More</Link>

                </div>
                <div className="col-12 col-sm-6">
                    <div className="text-right">
                        <img className='d-none d-md-block' style={{width:"100%",height:"475px"}} src={about} alt="about us" />
                    </div>
                </div>
            </div>
        </div>
    )
}
