import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import './dashboard.css'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import BorderAllIcon from '@mui/icons-material/BorderAll';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import BugReportIcon from '@mui/icons-material/BugReport';
import GuideTour from '../../components/guide/GuideTour';
import { steps } from './guidSteps';
import { apiErrorManagemnt2 } from '../../api/Custom';
import Swal from 'sweetalert2';
import { savePaymentDetails } from '../recharge/payment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails } from '../../store/userSlice';
import { useGetSellerDetailsQuery } from '../../hooks/RTK_Service/userLoginRegister';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [paymentLoadder, setPaymentLoadder] = useState(false);

  const userReq = useGetSellerDetailsQuery();
  if (userReq?.isError) {
    apiErrorManagemnt2(userReq?.error);
  }

  if (userReq?.isSuccess) {
    dispatch(setUserDetails({ user: userReq?.data }));
  }


  const firstTimeUser = () => {
    if (userReq?.data?.user?.gstIn === null || userReq?.data?.user?.gstIn === "") {
      Swal.fire({
        title: "GST number not present. Please provide first.",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Provide Here",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          navigate("/dashboard/update_profile");
        }
      });
      return;
    }

    setPaymentLoadder(true)
    const data = {
      amt: 100,
      units: 200,
      billing_plan: 'FT',
    }

    savePaymentDetails(data).then(res => {
      setPaymentLoadder(false)
    })

  }
  return (
    <div className='container-fluid'>
      <div className='spaceBetween'>
        <GuideTour action={false} guideSteps={steps} />
        <RefreshIcon className='pointer' onClick={()=>{userReq?.refetch()}} />
      </div>
      <div className="row mb-4 mt-2">


        <div className="col-12 col-sm-6 col-md-3 my-1" >
          <div className="order-tab card dashboard_card px-1 py-4" style={{ borderLeft: '8px solid #1976d2' }}>
            <div style={{ display: 'flex' }}>
              <i className='fa-solid fa-indian-rupee-sign center text-muted dash-icon-card'>
                <BorderAllIcon style={{ fontSize: "50px" }} />
              </i>
              <div className="dash-text-card">
                <h2 className='text-muted' ><b>Orders</b></h2>
                <h4 className='text-muted'>0</h4>
              </div>

            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-3 my-1" >
          {/* onClick={() => { navigate("/dashboard/recharge_plans") }} */}
          {userReq?.data?.totalCountOfPayment === 0 ?
            <div onClick={() => { firstTimeUser() }} className="card dashboard_card px-1 py-4" style={{ borderLeft: '8px solid rgb(25 207 37)', cursor: "pointer" }}>
              <div style={{ display: 'flex' }}>
                <i className='fa-solid fa-indian-rupee-sign center text-success dash-icon-card'><CurrencyRupeeIcon style={{ fontSize: "50px" }} /></i>
                <div className="dash-text-card">
                  <h2 className='text-success' ><b>Recharge</b> {
                    paymentLoadder &&
                    <CircularProgress
                      variant="indeterminate"
                      disableShrink
                      size={25}
                      thickness={4}
                      color="inherit"
                    />} </h2>
                  <small className='text-muted' >click to get 200 points for 100/- recharge for first time user.</small>
                </div>
              </div>
            </div>
            :
            <div onClick={() => { navigate("/dashboard/recharge_plans") }} className="card dashboard_card px-1 py-4" style={{ borderLeft: '8px solid rgb(25 207 37)' }}>
              <div style={{ display: 'flex' }}>
                <i className='fa-solid fa-indian-rupee-sign center text-success dash-icon-card'><CurrencyRupeeIcon style={{ fontSize: "50px" }} /></i>
                <div className="dash-text-card">
                  <h2 className='text-success' ><b>Recharge</b></h2>
                  <h4 className='text-muted'>{userReq?.data?.user?.totalCreditsPoints}</h4>
                </div>
              </div>
            </div>
          }

        </div>

        <div className="col-12 col-sm-6 col-md-3 my-1" >
          <Link className='link' to={"/dashboard/manage_products"}>
            <div className="card dashboard_card px-1 py-4" style={{ borderLeft: '8px solid rgb(210 207 25)' }}>
              <div style={{ display: 'flex' }}>

                <i className='fa-solid fa-box center text-warning dash-icon-card'><Inventory2Icon style={{ fontSize: "50px" }} /></i>
                <div className="dash-text-card">
                  <h2 className='text-warning' ><b>Products</b></h2>
                  <h4 className='text-muted'>{userReq?.data?.totalProductCount}</h4>
                </div>
              </div>
            </div>
          </Link>

        </div>

        <div className="col-12 col-sm-6 col-md-3 my-1" >
          <div className="card dashboard_card px-1 py-4 " style={{ borderLeft: '8px solid tomato', }}>
            <div style={{ display: 'flex' }}>
              <i className='fa-solid fa-bug center text-danger dash-icon-card'><BugReportIcon style={{ fontSize: "50px" }} /></i>
              <div className="dash-text-card">
                <h2 className='text-danger' ><b>Errors</b></h2>
                <p className='text-muted'>0</p>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div className="row">
        <div className="col-sm-6 ">

          <div className="row">
            <div className="col-12 col-sm-6 ">
              <div className="card py-4 px-2">
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 1, mb: 1, py: 2, }}
                >
                  setup inventory management
                </Button>

              </div>
            </div>

            <div className="col-12 col-sm-6 ">
              <div className="card py-4 px-2">
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 1, mb: 1, py: 2, }}
                >problem related sku</Button>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 mt-3">
            <div className="card px-2">
              <div>
                <Link to="/dashboard/job_batch_management">
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1, mb: 1, py: 2, }}
                  >Job/Batch Management</Button>
                </Link>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 1, mb: 1, py: 2, }}
                >GST/Report Management</Button>
                <Link to="/dashboard/manage_channel">
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1, mb: 1, py: 2, }}
                  >Manage Channel</Button>
                </Link>
                <Link to="/dashboard/manage_stock">
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1, mb: 1, py: 2, }}
                  >Manage Stock</Button>
                </Link>
                <Link to="/dashboard/recharge_plans">
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1, mb: 1, py: 2, }}
                  >Recharge You'r Account</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-lg-6 ">
          <div className="card p-4">
            <div className="mt-2">
              {/* <iframe width="100%" height="400" src="https://www.youtube.com/embed/SJNf0wbgrz8" frameBorder="0" allowFullScreen=""></iframe> */}
            </div>
            <div className='mt-2'>
              {/* <iframe width="100%" height="400" src="https://www.youtube.com/embed/BBXXfQsuHJ4" frameBorder="0" allowFullScreen=""></iframe> */}
            </div>
          </div>
        </div>

      </div>
    </div>

  )
}
