export const steps = [
    {
        target: 'body',
        title : `👋 Lets start the marketplace page quick information tour`,
        placement: 'center',
        
    },
    {
        target: '.activeMarketplace',
        title: 'In section those marketplaces are present those connection request',
        placement:'right'
    },
    {
        target: '.registerdMarketplace',
        title: 'In section those marketplaces are present those connection request',
        placement:'left'
    },
    {
        target: '.marketplaceInfo',
        title: 'Here marketplace all information present',
        placement:'bottom'
    },
    {
        target: '.sendRequestBtn',
        title: 'If you want to connect this market place then click on request to add button',
        placement:'left'
    },
    {
        target: 'body',
        title: 'If you click on request to add then wait for some time to accept your request by marketplace',
        placement:'center'
    },
    {
        target: 'body',
        title: 'Ones request is accepted then this request is showing in left side box (manage marketplace)',
        placement:'center'
    },
    {
        target: 'body',
        title: 'After showing the request click on the toggle button for sharing product information to that marketplace.',
        placement:'center'
    },
    {
        target: '.activeMarketplaceInfo',
        title: 'Active marketplace info showing here',
        placement:'right'
    },
    {
        target: '.amStatus',
        title: 'marketplace status if active means sharing the products info on there marketplace and inactive means not sharing the product details. make sure it is active.',
        placement:'right'
    },
    {
        target: '.amToggleBtn',
        title: 'this toggle button to active and inactivate marketplace',
        placement:'bottom'
    },

]