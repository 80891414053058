import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
// import { saveStockDetail, updateStockDetails } from '../../../api/manageStock';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { DASHBOARD_BASE_PATH, MANAGE_STOCK_PATH, UPDATE_TYPE_STOCK } from '../../../custome/constants';
import { useUpdateRequestData } from '../../../hooks/useUpdateRequestData';
import { removeUpdateDetails } from '../../../store/updateData';
import { useSaveStockDetailMutation, useUpdateStockDetailsMutation } from '../../../hooks/RTK_Service/stockApi';
import { apiErrorManagemnt2 } from '../../../api/Custom';
import Swal from 'sweetalert2';


export default function AddUpdateStock() {
    const [inventoryType, setInventoryType] = useState(null);
    const navigate = useNavigate();
    const warehouse = useSelector((state) => state.warehouse.warehouse)
    const updatedData = useUpdateRequestData(UPDATE_TYPE_STOCK);
    const dispatch = useDispatch();
    const [stockError, setStockError] = useState({ isError: false, type: null, message: null });

    const [saveStockMutation,{isError : saveStockIsError, isLoading : saveIsLoading, error: saveStockError}] = useSaveStockDetailMutation();
    const [updateStockMutation,{isError : updateStockIsError, isLoading : updateIsLoading, error: updateStockError}] = useUpdateStockDetailsMutation();

    if(saveStockIsError){
        apiErrorManagemnt2(saveStockError)
    }

    if(updateStockIsError){
        apiErrorManagemnt2(updateStockError)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const invType = inventoryType === 'GOOD' ? 1 : inventoryType === 'BAD' ? 2 : 0;
        var numberRegex = /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/;
       
        if (updatedData == null && data.get("SKU")?.trim() == null || data.get("SKU")?.trim().length == 0) {
            setStockError({ isError: true, type: 'sku', message: "sku is required field" });
        } else if (data.get("shelfCode")?.trim() == null || data.get("shelfCode")?.trim().length == 0) {
            setStockError({ isError: true, type: 'shelfCode', message: "sku is required field" });
        } else if (numberRegex.test(data.get("quantity"))===false) {
            setStockError({ isError: true, type: 'quantity', message: "enter valid number here." });
        } else if (numberRegex.test(data.get("item length")) ===false) {
            setStockError({ isError: true, type: 'item length', message: "enter valid number here." });
        } else if (numberRegex.test(data.get("item height")) === false) {
            setStockError({ isError: true, type: 'item height', message: "enter valid number here." });
        } else if (numberRegex.test(data.get("item width")) == false) {
            setStockError({ isError: true, type: 'item width', message: "enter valid number here." });
        } else if (numberRegex.test(data.get("item weight")) == false) {
            setStockError({ isError: true, type: 'item weight', message: "enter valid number here." });
        } else if (numberRegex.test(data.get("inventoryType")) == false) {
            setStockError({ isError: true, type: 'inventoryType', message: "invalid inventory type.." });
        } else if (numberRegex.test(data.get("selling price")) == false) {
            setStockError({ isError: true, type: 'selling price', message: "invalid selling price.." });
        } else if (numberRegex.test(data.get("min selling price")) == false) {
            setStockError({ isError: true, type: 'min selling price', message: "invalid min selling price.." });
        } else {
            setStockError({ isError: false, type: null, message: null });
            var resultData = {
                sku: data.get("SKU")?.trim(),
                shelfCode: data.get("shelfCode")?.trim(),
                quantity: data.get("quantity"),
                itemLength: data.get("item length"),
                itemHeight: data.get("item height"),
                itemWidth: data.get("item width"),
                itemWeight: data.get("item weight"),
                warehouseId: warehouse?.warehouseId,
                inventoryType: data.get("inventoryType"),
                sellingPrice: data.get("selling price"),
                minSellingPrice: data.get("min selling price"),
                comboData: data.get("combo data")
            }

            if (updatedData === null) {
                saveStockMutation(resultData).then((res) => {
                    if (res) {
                        if(res?.data?.status===0){
                            Swal.fire("Success",res?.data?.message,"success");
                        }
                        navigate(DASHBOARD_BASE_PATH + MANAGE_STOCK_PATH)
                    }
                })
            } else if (updatedData !== null) {
                resultData = { ...resultData, stockId: updatedData.stockId }
                updateStockMutation(resultData).then((res) => {
                    if (res) {
                        if(res?.data?.status===0){
                            Swal.fire("Success",res?.data?.message,"success");
                        }
                        dispatch(removeUpdateDetails);
                        navigate(DASHBOARD_BASE_PATH + MANAGE_STOCK_PATH)
                    }
                })
            }
        }

    }
    return (
        <>
            <div className='text-center'>
                <div className=" text-muted">
                    <h2>{updatedData != null ? 'UPDATE' : 'ADD'} STOCK</h2>
                </div>
            </div>
            {
                useSelector((state) => state.warehouse.warehouse_is_selected) ?
                    <Box className='center' component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
                        <Grid container sm={12} md={5} >
                            <Grid item xs={12}>
                                <span className='text-muted text-center'><b>Note : </b> stock {updatedData != null ? 'update' : 'add'} in  <i>{warehouse.warehouseName}</i> warehouse.</span>
                            </Grid>
                            <Grid item xs={12} mt={1}>
                                <TextField
                                    autoComplete="given-name"
                                    name="SKU"
                                    disabled={updatedData != null ?true:false}
                                    required
                                    error={stockError.type === 'sku' && stockError.isError}
                                    helperText={stockError.type === 'sku' && stockError.message}
                                    fullWidth
                                    id="SKU"
                                    label="SKU"
                                    multiline
                                    defaultValue={updatedData != null ? updatedData?.sku : ''}
                                    autoFocus
                                    sx={{ mb: 2 }}
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <TextField
                                    autoComplete="given-name"
                                    name="quantity"
                                    required
                                    error={stockError.type === 'quantity' && stockError.isError}
                                    helperText={stockError.type === 'quantity' && stockError.message}
                                    fullWidth
                                    id="quantity"
                                    multiline
                                    defaultValue={updatedData != null ? updatedData?.quantity : ''}
                                    label="Quantity"
                                    sx={{ mb: 2 }}
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <TextField
                                    autoComplete="given-name"
                                    name="selling price"
                                    required
                                    fullWidth
                                    error={stockError.type === 'selling price' && stockError.isError}
                                    helperText={stockError.type === 'selling price' && stockError.message}
                                    multiline
                                    defaultValue={updatedData != null ? updatedData?.sellingPrice : ''}
                                    id="selling price"
                                    label="Selling price"
                                    sx={{ mb: 2 }}
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <TextField
                                    autoComplete="given-name"
                                    name="min selling price"
                                    required
                                    error={stockError.type === 'min selling price' && stockError.isError}
                                    helperText={stockError.type === 'min selling price' && stockError.message}
                                    fullWidth
                                    multiline
                                    defaultValue={updatedData != null ? updatedData?.minSellingPrice : ''}
                                    id="min selling price"
                                    label="Minimum selling price"
                                    sx={{ mb: 2 }}
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <TextField
                                    autoComplete="given-name"
                                    name="shelfCode"
                                    required
                                    error={stockError.type === 'shelfCode' && stockError.isError}
                                    helperText={stockError.type === 'shelfCode' && stockError.message}
                                    fullWidth
                                    multiline
                                    defaultValue={updatedData != null ? updatedData?.shelfCode : ''}
                                    id="shelfCode"
                                    label="Self code"
                                    sx={{ mb: 2 }}
                                />
                            </Grid>

                            <Grid container xs={12} mb={2}>
                                <FormControl sx={{ minWidth: "100%", }}>
                                    <InputLabel id="invTypelabel">Inventory Type</InputLabel>
                                    <Select
                                        name="inventoryType"
                                        labelId="invTypelabel"
                                        id="inventoryType"
                                        defaultValue={updatedData != null ? updatedData.inventoryType : '0'}

                                        label="Inventory type"
                                    // onChange={event => { setInventoryType(event.target.value) }}
                                    >
                                        <MenuItem value="0">
                                            <em>None</em>
                                        </MenuItem>

                                        <MenuItem key='invTypeId' value="1">GOOD</MenuItem>
                                        <MenuItem key='invTypeId' value="2">BAD</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} pr={1}>
                                <TextField
                                    autoComplete="given-name"
                                    name="item length"
                                    required
                                    error={stockError.type === 'item length' && stockError.isError}
                                    helperText={stockError.type === 'item length in cm' && stockError.message}
                                    fullWidth
                                    multiline
                                    defaultValue={updatedData != null ? updatedData?.itemLength : ''}
                                    id="item length"
                                    label="Item length in cm"
                                    sx={{ mb: 2 }}
                                />
                            </Grid>
                            <Grid item xs={6} pl={1}>
                                <TextField
                                    autoComplete="given-name"
                                    name="item height"
                                    required
                                    error={stockError.type === 'item height' && stockError.isError}
                                    helperText={stockError.type === 'item height in cm' && stockError.message}
                                    multiline
                                    defaultValue={updatedData != null ? updatedData?.itemHeight : ''}
                                    fullWidth
                                    id="item height"
                                    label="Item height in cm"
                                    sx={{ mb: 2 }}
                                />
                            </Grid>

                            <Grid item xs={6} pr={1}>
                                <TextField
                                    autoComplete="given-name"
                                    name="item width"
                                    required
                                    error={stockError.type === 'item width' && stockError.isError}
                                    helperText={stockError.type === 'item width in cm' && stockError.message}
                                    multiline
                                    defaultValue={updatedData != null ? updatedData?.itemWidth : ''}
                                    fullWidth
                                    id="item width"
                                    label="Item width in cm"
                                    sx={{ mb: 2 }}
                                />
                            </Grid>
                            <Grid item xs={6} pl={1}>
                                <TextField
                                    autoComplete="given-name"
                                    name="item weight"
                                    error={stockError.type === 'item weight' && stockError.isError}
                                    helperText={stockError.type === 'item weight in gm' && stockError.message}
                                    required
                                    multiline
                                    defaultValue={updatedData != null ? updatedData?.itemWeight : ''}
                                    fullWidth
                                    id="item weight"
                                    label="Item weight in gm"
                                    sx={{ mb: 2 }}
                                />
                            </Grid>



                            <Grid item xs={12} mt={3} >
                                <Button
                                    fullWidth
                                    type='submit'
                                    variant="contained"
                                    sx={{ mb: 2, py: 2, }}
                                >
                                    Submit
                                </Button>
                            </Grid>

                        </Grid>
                    </Box>
                    :
                    <div className='center'>
                        <div className="alert alert-danger p-4 my-5">
                            <h2><i className="fa-solid fa-circle-exclamation fa-fade fa-1x" style={{ color: "#e32b16" }}></i> Please select warehouse first !!!</h2>
                            <div className="right my-3">
                                <Link to="/dashboard/manage_stock" className="btn btn-outline-danger">select warehouse</Link>

                            </div>
                        </div>
                    </div>
            }


        </>
    )
}
