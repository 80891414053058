import React, {  useState } from 'react'
import { useGetAllWarehousesQuery } from '../../hooks/RTK_Service/warehouse';
import { apiErrorManagemnt2 } from '../../api/Custom';
import LinkIthinkLogistic from './ithinkLogistic/LinkIthinkLogistic';
import ShippingPartnerCards from '../../components/custom/ShippingPartnerCards';
import ithinkLogisticIcon from '../../files/imgs/ithinkLogistic.png'
import blueDartIcon from '../../files/imgs/blueDart.png'
import IthinkLogisticTable from './ithinkLogistic/IthinkLogisticTable';

export default function ShippingPartnerDashboard() {
  const [shippingPartner, setShippingPartner] = useState(null);
  const [openTable,setOpenTable]=useState(null);

  const warehouseData = useGetAllWarehousesQuery({
    page: 1,
    limit: 1000,
  })
  if (warehouseData?.isError) {
    apiErrorManagemnt2(warehouseData?.error);
  }

 

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setWarehouseIds(
  //     typeof value === 'number' ? value.split(',') : value,
  //   );
  // };
  var options = [];

  if (warehouseData?.isSuccess) {
    warehouseData?.data?.data?.content?.map((wd) => {
      options.push({
        value: wd.warehouseId,
        label: wd.warehouseName,
      });
    })
  }

  return (
    <div className='container-fluid'>
      {(shippingPartner==null && openTable==null ) &&
        <div className='row my-2'>
          <div className="col-12 col-md-4 my-3">
            <ShippingPartnerCards image={ithinkLogisticIcon} title={"iThink Logistics"} desc={'deliver your order by using iThink logistic shipping partner service.'} setShippingPartner={setShippingPartner} spName={'ithinkLogistic'} setOpenTable={setOpenTable} openTableValue={'ithinkLogisticTable'} />
          </div>

          <div className="col-12 col-md-4 my-3">
            <ShippingPartnerCards image={blueDartIcon} title={"Blue Dart"} desc={'deliver your order by using blue dart shipping partner service.'} setShippingPartner={setShippingPartner} spName={'blueDart'} setOpenTable={setOpenTable} openTableValue={'blueDartTable'} />
          </div>
        </div>
      }

      {
        (shippingPartner === "ithinkLogistic" && openTable==null ) &&
        <LinkIthinkLogistic options={options} setOpenTable={setOpenTable} setShippingPartner={setShippingPartner} />
      }

      {
        (shippingPartner === "blueDart" || openTable=="blueDartTable") &&
        <div className='alert alert-info my-5'>Opps, sorry blue dart service is not implement yet, <button className='btn btn-sm ' onClick={()=>{setOpenTable(null);setShippingPartner(null)}}><u>back</u></button></div>
      }

      {/* for tables */}
      {
        (openTable === "ithinkLogisticTable" && shippingPartner==null) &&
        <IthinkLogisticTable setOpenTable={setOpenTable} setShippingPartner={setShippingPartner}/>
      }
    
    </div>
  )
}
