import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../components/loader/Loader';
import CachedIcon from '@mui/icons-material/Cached';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Button, IconButton, Menu, MenuItem, Pagination, Stack, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_PRODUCT_PATH, DASHBOARD_BASE_PATH, MANAGE_PRODUCT_PATH, MANAGE_STOCK_PATH, UPDATE_TYPE_PRODUCT } from '../../../custome/constants';
import { removeUpdateDetails, setUpdateDetails } from '../../../store/updateData';
import { apiErrorManagemnt, apiErrorManagemnt2 } from '../../../api/Custom';
import { useDeleteProductsMutation, useGetProductsByWarehouseIdQuery, useSearchProductMutation } from '../../../hooks/RTK_Service/productApi';
import Swal from 'sweetalert2';
import { removeWarehouseData } from '../../../store/warehouseSlice';
import SearchProduct from './SearchProduct';
import GuideTour from '../../../components/guide/GuideTour';
import { steps } from './guideSteps';
import TakeTourBtn from '../../../components/custom/TakeTourBtn';
import Information from '../../../components/custom/Information';
import { dawnloadExcelFile } from '../../../api/JobManagement';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Product_variations_model from './Product_variations_model';
import BtnLodderIcon from '../../../components/custom/BtnLodderIcon';
import LinearProressBar from '../../../components/custom/LinearProressBar';

const ITEM_HEIGHT = 48;

export default function ManageProducts() {
    const [pageNumber, setPageNumber] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [startTour, setStartTour] = useState(false);
    const [isActiveSearch, setActiveSearch] = useState(false);
    const [deleteProductMutation, { isError: deleteIsError, error: deleteError, isLoading:deleteProductLoading }] = useDeleteProductsMutation();
    const [searchProductMutation, { isError: searchIsError, error: searchError, data: searchData, isSuccess: searchSuccess, isLoading: searchProductLoading }] = useSearchProductMutation();
    const warehouseIsSelected = useSelector((state) => state.warehouse.warehouse_is_selected) ? false : true;
    const [anchorEl, setAnchorEl] = useState(null);
    const [pvmOpen, setPvmOpen] = useState(false);
    const [pvmProduct, setPvmProduct] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null); // Initialize selectedIndex state
    const [loading,setLoading] = useState(false);

    const handlePVMClickOpen = (product) => {
        setPvmOpen(true);
        setPvmProduct(product);
    };

    const handlePVMClose = () => {
        setPvmOpen(false);
    };

    const open = Boolean(anchorEl);
    const handleProductMenuClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSelectedIndex(index)
    };
    const handleProductMenuClose = () => {
        setAnchorEl(null);
    };



    //if warehouse not seleted then open warehouseletion panal
    if (warehouseIsSelected) {
        navigate(DASHBOARD_BASE_PATH + MANAGE_STOCK_PATH + "?tpath=" + MANAGE_PRODUCT_PATH);
    }

    const warehouse = useSelector((state) => state.warehouse.warehouse)
    const dataObj = {
        page: pageNumber,
        warehouseId: warehouse?.warehouseId
    }

    const { isLoading, data, isError, error, isFetching, isSuccess } = useGetProductsByWarehouseIdQuery(dataObj)


    if (deleteIsError) {
        apiErrorManagemnt2(deleteError);
    }
    if (isLoading || isFetching) {
        return <Loader action={true} />
    }

    if (isError) {
        if (warehouseIsSelected == false)
            apiErrorManagemnt(error)
    }

    const deleteProductHandler = (productRefid) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteProductMutation(productRefid).then((res) => {
                    if (res?.data?.status === 0) {
                        swalWithBootstrapButtons.fire({
                            title: "Deleted!",
                            text: "Your product details has been deleted.",
                            icon: "success"
                        });
                    } else {
                        swalWithBootstrapButtons.fire({
                            title: "Error",
                            text: res?.data?.message,
                            icon: "error"
                        });
                    }
                })
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                    title: "Cancelled",
                    text: "Your product info is safe :)",
                });
            }
        });

    }


    const updateProductHandler = (data) => {
        dispatch(setUpdateDetails({ type: UPDATE_TYPE_PRODUCT, data: data }))
        navigate(DASHBOARD_BASE_PATH + ADD_PRODUCT_PATH);
    }

    const addNewProductHandler = () => {
        dispatch(removeUpdateDetails());
        navigate(DASHBOARD_BASE_PATH + ADD_PRODUCT_PATH);
    }

    const handleClickChangeWarehouse = () => {
        dispatch(removeWarehouseData());
        dispatch(removeUpdateDetails());
        navigate(DASHBOARD_BASE_PATH + MANAGE_STOCK_PATH + "?tpath=" + MANAGE_PRODUCT_PATH)
    }



    const handleSearch = (type, value) => {
        setActiveSearch(true);
        var obj = {
            searchBy: type,
            searchValue: value,
            warehouseId: warehouse?.warehouseId
        }
        searchProductMutation(obj);
    }


    const actionButton = (productData, index) => {
        return (
            <>
                <IconButton
                    aria-label="more"
                    id={`long-button-${index}`}
                    aria-controls={open ? `long-menu-${index}` : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={(event) => handleProductMenuClick(event, index)} // Pass productData directly
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id={`long-menu-${index}`}
                    MenuListProps={{
                        'aria-labelledby': `long-button-${index}`,
                    }}
                    anchorEl={anchorEl}
                    open={open && selectedIndex === index}
                    onClose={handleProductMenuClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    }}
                >
                    <MenuItem onClick={() => { handleProductMenuClose(); updateProductHandler(productData) }}>
                        edit
                    </MenuItem>
                    <MenuItem onClick={() => { handleProductMenuClose(); deleteProductHandler(productData.productRefId) }}>
                        delete
                    </MenuItem>
                    <MenuItem onClick={() => { handleProductMenuClose(); handlePVMClickOpen(productData) }}>
                        variation
                    </MenuItem>
                </Menu>
            </>
        )
    }

    const handleDownloadExcelFile = async () => {
        setLoading(true);
        await dawnloadExcelFile(warehouse?.warehouseId, "PRODUCT_DATA");
        setLoading(false);
      }


    return (
        <>

            <div className='container-fluid text-muted'>
                {
                    startTour &&
                    <GuideTour action={startTour} guideSteps={steps} />
                }
                <div className='mt-2 spaceBetween'>
                    <div className="text-center text-muted alignCenter" >
                        <h3>MANAGE PRODUCT</h3>
                        <Information info={process.env.REACT_APP_MANAGEPRODUCT_INFORMATION_MSG} />
                    </div>
                    <div onClick={() => { setStartTour(true) }}>
                        <TakeTourBtn />
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className='left my-2'>
                        <Button onClick={() => { addNewProductHandler() }} variant="contained" className="mx-1 addProductBtn" ><AddCircleOutlineIcon />&nbsp;Add product</Button>
                        <Button className='importProductDataBtn' onClick={() => { dispatch(removeWarehouseData); navigate("/dashboard/job_batch_management") }} variant="contained" ><UploadFileIcon />&nbsp; Import product data</Button>
                        <Button disabled={loading} onClick={() => { handleDownloadExcelFile() }} variant="contained" className="mx-1" >{loading ? <BtnLodderIcon action={loading} /> : <CloudDownloadIcon />}&nbsp;Exportt product data</Button>
                    </div>
                    <div className='searchPannel'>
                        <SearchProduct handleSearch={handleSearch} />
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                    <small className='text-muted warehouseName'>Warehouse : <b>{warehouse?.warehouseName}</b></small>&nbsp;&nbsp;
                    <small className='mt-1'>
                        <span className='text-muted'><b>change warehouse?</b></span>&nbsp;&nbsp;
                        <Tooltip onClick={() => { handleClickChangeWarehouse() }} className='text-primary pointer mr-3 changeWarehouseBtn' title="Change warehouse">
                            <CachedIcon />
                        </Tooltip>
                    </small>
                </div>
                {data &&
                    <div className="table-responsive">
                        <LinearProressBar value={deleteProductLoading || searchProductLoading} />
                        <table className="table table-hover mt-2">
                            <thead style={{ borderBottom: "1px solid black" }}>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">SKU</th>
                                    <th scope="col">Title</th>
                                    <th scope='col'>Actions</th>
                                </tr>
                            </thead>
                            <tbody className='productTable'>
                                {
                                    isActiveSearch ?
                                        searchData?.searchProducts?.map((data, index) => {
                                            return (
                                                <tr key={data.productRefId}>
                                                    <td >{data.productRefId}</td>
                                                    <td >{data.sku}</td>
                                                    <td >{data.title}</td>
                                                    {/* <td><button className='btn btn-sm btn-outline-warning'>Update</button></td>
                                                    <td><button  className='btn btn-sm btn-outline-danger'>Delete</button></td> */}
                                                    <td key={data.productRefId}>
                                                        {actionButton(data, index)}
                                                    </td>
                                                </tr>

                                            )
                                        })
                                        :
                                        data?.data?.content?.map((productData, index) => {
                                            return (
                                                <tr key={productData.productRefId}>
                                                    <td >{productData.productRefId}</td>
                                                    <td >{productData.sku}</td>
                                                    <td >{productData.title}</td>
                                                    {/* <td><button className='btn btn-sm btn-outline-warning'>Update</button></td>
                                                    <td><button  className='btn btn-sm btn-outline-danger'>Delete</button></td> */}
                                                    <td key={productData.productRefId}>
                                                        {actionButton(productData, index)}
                                                    </td>
                                                </tr>

                                            )
                                        })}
                            </tbody>
                        </table>
                        <div className="right">
                            <Stack spacing={2}>
                                {
                                    isActiveSearch == false &&
                                    <Pagination color="primary" count={data?.data?.totalPages} page={pageNumber} onChange={(e, v) => setPageNumber(v)} />
                                }
                            </Stack>
                        </div>
                    </div>
                }
            </div>

            <Product_variations_model open={pvmOpen} warehouse={warehouse} close={handlePVMClose} product={pvmProduct} />
        </>
    )
}
