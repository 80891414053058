import axios from "axios";
import { getToken } from "../custome/action";
import Swal from "sweetalert2";
import { BASE_URL, apiErrorManagemnt } from "./Custom";
import { Toast } from "../custome/popupMessage";


export const checkSkuPresentOrNotInStock = (sku) => {
    const result = axios.get(`${BASE_URL}/manageecom/api/checkSkuPresentOrNotInStock?sku=${sku}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        }
    }).then(
        (response) => {
            return response.data;
        },
        (error) => {
            apiErrorManagemnt(error);
            return error;
        }
    ).catch(err => {
        console.error(err);
        return (err)
    });
    return result;
}

export const saveComboData = async (data) => {
    const result = axios.post(`${BASE_URL}/manageecom/api/saveComboData`,data, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        }
    }).then(
        (response) => {
            return response.data;
        },
        (error) => {
            apiErrorManagemnt(error);
            return error;
        }
    ).catch(err => {
        console.error(err);
        return (err)
    });
    return result;
}


export const getComboDataDetails=async(pageNumber)=>{
    const result= axios.get(`${BASE_URL}/manageecom/api/getComboData?pageNumber=${pageNumber-1}`, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          'Content-Type': 'application/json'
        }
      }).then(
        (response) => {
          return response.data;
        },
        (error) => {
          apiErrorManagemnt(error);
          return error;
        }
      ).catch(err => {
        console.error(err);
        return (err)
      });
      return result;
}

export const getComboDataByIdDetails=async(id)=>{
    const result= axios.get(`${BASE_URL}/manageecom/api/getComboDataById?comboDataId=${id}`, {
        headers: {
          'Authorization': 'Bearer ' + getToken(),
          'Content-Type': 'application/json'
        }
      }).then(
        (response) => {
          return response?.data;
        },
        (error) => {
          apiErrorManagemnt(error);
          return error;
        }
      ).catch(err => {
        console.error(err);
        return (err)
      });
      return result;
}

export const updateJSONComboData=async(data)=>{
  const result= axios.post(`${BASE_URL}/manageecom/api/updateJsonComboData`,data ,{
      headers: {
        'Authorization': 'Bearer ' + getToken(),
        'Content-Type': 'application/json'
      }
    }).then(
      (response) => {
        return response?.data;
      },
      (error) => {
        apiErrorManagemnt(error);
        return error;
      }
    ).catch(err => {
      console.error(err);
      return (err)
    });
    return result;
}

