import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react'
import { useUpdateRequestData } from '../../../../hooks/useUpdateRequestData';
import { UPDATE_TYPE_PRODUCT } from '../../../../custome/constants';
import { UpdateDisabled } from '@mui/icons-material';

export default function StepTwo({ setStepTwoData,stepTwoData,handleBack, handleNext }) {
    const [stepTwoErrors,setStepTwoErrors] = useState({isError:false,type:null,message:''}); 
    const updateData = useUpdateRequestData(UPDATE_TYPE_PRODUCT);

    const handleSubmitStepTwoDetails = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const title = data.get("productTitle");
        const hsn = data.get("productHSNcode");
        const material = data.get("productMaterial")
        const brand = data.get("productBrand")
        const color = data.get("productColor")
        const salePrice = data.get('productSalePrice')
        const mrp = data.get("productMrp")
        const keywords = data.get("productKeywords")
        const description = data.get("productDescription");
        const minimumOrderQuantity = data.get("minimumOrderQuantity");
        var numberRegex = /^(?:-(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))|(?:0|(?:[1-9](?:\d{0,2}(?:,\d{3})+|\d*))))(?:.\d+|)$/;


        if(title===null || title.length<=0){
            setStepTwoErrors({isError:true,type:'title',message:'product title is required !!'})
        }else if(hsn===null || hsn<=0){
            setStepTwoErrors({isError:true,type:'hsn',message:'product hsn code is required !!'})
        }else if(material===null || material.length<=0){
            setStepTwoErrors({isError:true,type:'material',message:'product material is required !!'})
        }else if(brand===null || brand.length<=0){
            setStepTwoErrors({isError:true,type:'brand',message:'product brand is required !!'})
        }else if(color===null || color.length<=0){
            setStepTwoErrors({isError:true,type:'color',message:'product color is required !!'})
        }else if(numberRegex.test(salePrice)===false || salePrice===null || salePrice<=0 || salePrice<=0){
            setStepTwoErrors({isError:true,type:'salePrice',message:'product sale price is required >0 !!'})
        }else if(numberRegex.test(mrp)===false || mrp===null || mrp.length<=0 || mrp<=0 ){
            setStepTwoErrors({isError:true,type:'mrp',message:'product mrp is required >0 !!'})
        }else if(keywords===null || keywords.length<=0){
            setStepTwoErrors({isError:true,type:'keywords',message:'product keywords is required !!'})
        }else if(description===null || description.length<=0){
            setStepTwoErrors({isError:true,type:'description',message:'product description is required !!'})
        }else if (minimumOrderQuantity<1){
            setStepTwoErrors({isError:true,type:'moq',message:'minimum order qunatity must be greter than zero'})
        }else{
            setStepTwoErrors({isError:false,type:null,message:''})
            var obj = {
                title,
                hsn,
                material,
                brand,
                color,
                salePrice,
                mrp,
                keywords,
                description,
                minimumOrderQuantity
            }
            setStepTwoData(obj)
            handleNext();
        }
    }

    return (
        <div>
            <Box className='' component="form" noValidate onSubmit={handleSubmitStepTwoDetails} sx={{ mt: 3 }}>
                <Box className="center">
                    <Grid container xs={12} sm={6} md={7} >
                        <Grid item xs={12} >
                            <TextField
                                autoComplete="given-name"
                                name="productTitle"
                                error={stepTwoErrors.type==='title' ? stepTwoErrors.isError :false }
                                helperText={stepTwoErrors.type==='title' && stepTwoErrors.message}
                                required
                                fullWidth
                                id="productTitle"
                                label="Product Title"
                                multiline
                                defaultValue={updateData!=null ? updateData?.title : stepTwoData?.title}
                                autoFocus
                                sx={{ mb: 2 }}

                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                autoComplete="given-name"
                                name="minimumOrderQuantity"
                                error={stepTwoErrors.type==='moq' ? stepTwoErrors.isError :false }
                                helperText={stepTwoErrors.type==='moq' && stepTwoErrors.message}
                                required
                                fullWidth
                                id="moq"
                                label="Product minimum order quantity"
                                multiline
                                defaultValue={updateData!=null ? updateData?.minimumOrderQuantity : stepTwoData?.minimumOrderQuantity}
                                autoFocus
                                sx={{ mb: 2 }}

                            />
                        </Grid>
                        <Grid item xs={12} md={6} pr={1}>
                            <TextField
                                autoComplete="given-name"
                                name="productHSNcode"
                                error={stepTwoErrors?.type==='hsn' && stepTwoErrors?.isError  }
                                helperText={stepTwoErrors?.type==='hsn' && stepTwoErrors?.message}
                                required
                                fullWidth
                                multiline
                                defaultValue={updateData!=null ? updateData?.hsnCode : stepTwoData?.hsn}
                                id="productHSNcode"
                                label="Product HSN code"
                                sx={{ mb: 2 }}

                            />
                        </Grid>
                        <Grid item xs={12} md={6} pl={1}>
                            <TextField
                                autoComplete="given-name"
                                name="productMaterial"
                                required
                                fullWidth
                                id="productMaterial"
                                label="Product material"
                                multiline
                                defaultValue={updateData!=null ? updateData?.material : stepTwoData?.material}
                                sx={{ mb: 2 }}
                                error={stepTwoErrors.type==='material' ? stepTwoErrors.isError :false }
                                helperText={stepTwoErrors.type==='material' && stepTwoErrors.message}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} pr={1}>
                            <TextField
                                autoComplete="given-name"
                                name="productBrand"
                                required
                                fullWidth
                                id="productBrand"
                                label="Product brand"
                                multiline
                                defaultValue={updateData!=null ? updateData?.brand : stepTwoData?.brand}
                                sx={{ mb: 2 }}
                                error={stepTwoErrors.type==='brand' ? stepTwoErrors.isError :false }
                                helperText={stepTwoErrors.type==='brand' && stepTwoErrors.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} pl={1}>
                            <TextField
                                autoComplete="given-name"
                                name="productColor"
                                required
                                fullWidth
                                id="productColor"
                                multiline
                                defaultValue={updateData!=null ? updateData?.color: stepTwoData?.color}
                                label="Product color"
                                sx={{ mb: 2 }}
                                error={stepTwoErrors.type==='color' ? stepTwoErrors.isError :false }
                                helperText={stepTwoErrors.type==='color' && stepTwoErrors.message}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} pr={1}>
                            <TextField
                                autoComplete="given-name"
                                name="productMrp"
                                required
                                fullWidth
                                id="productMrp"
                                label="Product mrp"
                                multiline
                                defaultValue={updateData!=null? updateData?.price: stepTwoData?.price}
                                sx={{ mb: 2 }}
                                error={stepTwoErrors.type==='mrp' ? stepTwoErrors.isError :false }
                                helperText={stepTwoErrors.type==='mrp' && stepTwoErrors.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} pl={1}>
                            <TextField
                                autoComplete="given-name"
                                name="productSalePrice"
                                required
                                fullWidth
                                multiline
                                defaultValue={updateData!=null ? updateData?.sale_price : stepTwoData?.sale_price}
                                id="productSalePrice"
                                label="Product sale price"
                                sx={{ mb: 2 }}
                                error={stepTwoErrors.type==='salePrice' ? stepTwoErrors.isError :false }
                                helperText={stepTwoErrors.type==='salePrice' && stepTwoErrors.message}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                                autoComplete="given-name"
                                name="productKeywords"
                                required
                                fullWidth
                                id="productKeywords"
                                label="Product Keywords"
                                multiline
                                defaultValue={updateData!=null ? updateData?.keywords: stepTwoData?.keywords}
                                sx={{ mb: 2 }}
                                error={stepTwoErrors.type==='keywords' ? stepTwoErrors.isError :false }
                                helperText={stepTwoErrors.type==='keywords' && stepTwoErrors.message}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                                autoComplete="given-name"
                                name="productDescription"
                                required
                                fullWidth
                                id="productDescription"
                                label="Product description"
                                multiline
                                defaultValue={updateData!=null ? updateData?.description : stepTwoData?.description}
                                rows={4}
                                sx={{ mb: 2 }}
                                error={stepTwoErrors.type==='description' ? stepTwoErrors.isError :false }
                                helperText={stepTwoErrors.type==='description' && stepTwoErrors.message}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        color="inherit"
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button variant="outlined" type='submit'>
                        Next
                    </Button>
                </Box>
            </Box>
        </div>
    )
}
