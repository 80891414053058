import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SELLERS_BASE_URL } from '../../api/Custom'
import { getToken } from '../../custome/action'

export const settlementApi = createApi({
    reducerPath: 'settlementApi',
    baseQuery: fetchBaseQuery({ baseUrl: SELLERS_BASE_URL }),
    tagTypes: ['settlement'],
    endpoints: (builder) => ({
        getSettlement: builder.query({
            query: (page) => ({
                url: `/getSettlementDetails?page=${page - 1}&limit=20`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getToken(),
                    'Content-Type': 'application/json'
                }
            }),
            providesTags: ['settlement']
        })

    })
})

export const { useGetSettlementQuery  } = settlementApi