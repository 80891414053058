import { Box, Button, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { saveChannelSettings } from '../../../api/ChannelSetting';
import axios from 'axios';
import { BASE_URL } from '../../../api/Custom';
import { getToken } from '../../../custome/action';
import Swal from 'sweetalert2';
import amazon_logo from '../../../files/imgs/amzon_logo2.png'
import { v4 as uuidv4 } from 'uuid';


const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};


export default function AmazonSetting({ infoData, amazon, handleAmazon }) {
    const [channelSetting, setChannelSettings] = useState(null);
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataResult = {
            sellerId: data.get('SellerID'),
            marketplaceId: data.get('MarketplaceID'),
            userId: infoData.userId,
            channelId: infoData.channelId,
            channel: infoData.channel
        };
        await saveChannelSettings(dataResult)
        handleAmazon()
    }

    const getChannelSetting = async () => {
        await axios.post(`${BASE_URL}/manageecom/api/getChannelSetting`, infoData, {
            headers: {
                'Authorization': 'Bearer ' + getToken(),
                'Content-Type': 'application/json'
            }
        }).then(
            (response) => {
                setChannelSettings(response.data);
                return response.data;
            },
            (error) => {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops, Something went wrong!',
                    text: error.message,
                })
                return error;
            }
        ).catch(err => {
            return (err)
        });
    }
    

    const authorizeManageecom = () => {
        const uuid = uuidv4();
        const state = `${uuid}~${infoData.channelId}`;
        sessionStorage.setItem("state", state);
        window.open('https://sellercentral.amazon.in/apps/authorize/consent?version=beta&application_id=amzn1.sp.solution.b87ea2fa-69bd-47c1-92d6-058e769e26ae&state=' + state,'_self')
    };

    useEffect(() => {
        const init = async () => {
            setChannelSettings(null);
            if (amazon) {
                getChannelSetting()


            }
        }
        init();
    }, [amazon])

    return (
        <div>

            <Modal
                open={amazon}
                onClose={handleAmazon}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <img src={amazon_logo} width={40} height={40} /> <b>AMAZON SETTINGS</b>
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="SellerID"
                            label="SellerID"
                            defaultValue={channelSetting?.sellerId}
                            multiline
                            name="SellerID"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="MarketplaceID"
                            multiline
                            label="Marketplace ID"
                            defaultValue={channelSetting?.marketplaceId}
                            id="MarketplaceID"
                        />

                        {/* <FormLabel id="demo-form-control-label-placement">Variation Listing </FormLabel>

                        <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="Variation_Listing"
                        >
                            <FormControlLabel value="Y" control={<Radio />} label="Enable" />
                            <FormControlLabel value="N" control={<Radio />} label="Disable" />
                        </RadioGroup>

                        <FormLabel id="demo-form-control-label-placement">List in Channel </FormLabel>

                        <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="List_in_Channel"

                        >

                            <FormControlLabel value="Y" control={<Radio />} label="Enable" />
                            <FormControlLabel checked value="N" control={<Radio />} label="Disable" />
                        </RadioGroup> */}

                        <Button
                            fullWidth
                            onClick={()=>{authorizeManageecom()}}
                            variant="contained"
                            sx={{ mt: 3, mb: 2, py: 2 }}
                        >
                            Authorize ManageEcom
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 2, py: 2, }}
                        >
                            Submit
                        </Button>

                    </Box>
                </Box>
            </Modal>
        </div>
    )
}
